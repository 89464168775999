import React from "react"
import PanelImage from "../../assets/images/Construction/Rectangle385.svg"
const SolarPanelWorking = () => {
  return (
    <div className="bg-[color:#E4E4E4] flex flex-col items-center p-10 md:p-20">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/2 flex flex-col justify-between">
          <div>
            <h3 className="text-black text-[32px] md:text-[34px] lg:text-[49.28px] font-SFProDisplayFont font-bold">
              How it Works?
            </h3>
            <h3 className="text-[#282828] text-[30px] leading-[1.1] md:text-[34px] md:leading-[1.0] opacity-[0.50] font-SFProDisplayFont font-normal">
              Embark on your solar journey with these simple steps{" "}
            </h3>
          </div>

          <ul className="text-[#282828] opacity-[0.50] text-[20px] font-SFProDisplayFont font-normal mt-4 lg:mt-0">
            <li>1. Consultation - Discuss your needs with our experts.</li>
            <li> 2. Assessment - We evaluate your site and energy requirements.</li>
            <li> 3. Installation - Our skilled team installs top-tier solar panels.</li>
            <li> 4. Support - Enjoy ongoing support for optimal performance.</li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <img src={PanelImage} className="object-cover" alt="" />
        </div>
      </div>
    </div>
  )
}
export default SolarPanelWorking
