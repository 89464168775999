import React, { useState } from "react";

import Button from "../Button";
import About from "../About";
import ProjectItem from "../ProjectItem";
import ServiceItem from "../ServiceItem";
import ServiceImg1 from "../../assets/images/Construction/ServiceImage1.png";
import ServiceImg2 from "../../assets/images/Construction/Rectangle95.png";
import ServiceImg3 from "../../assets/images/Construction/Rectangle97.png";
import ServiceImg4 from "../../assets/images/Construction/Rectangle98.png";
import ServiceImg5 from "../../assets/images/Construction/Rectangle100.png";
import ServiceImg6 from "../../assets/images/Construction/Rectangle101.png";
import ServiceImg7 from "../../assets/images/Construction/Rectangle103.png";
import ServiceImg8 from "../../assets/images/Construction/Rectangle105.png";
import ServiceImg9 from "../../assets/images/Construction/Rectangle88.png";
import ServiceImg10 from "../../assets/images/Construction/Rectangle89.png";
import ServiceImg from "../../assets/images/Construction/ServiceLargeImage.svg";
import AboutConstruction from "../../assets/images/AboutUs-Images/AboutConstruction.svg";
import CommercialFitOut from "../../assets/images/Construction/commercial-fit-outs.png";
import Extension from "../../assets/images/Construction/Additions-and-Extensions.png";
import InteriorBuildOut from "../../assets/images/Construction/Interior.png";
import HeroImg from "../../assets/images/Construction/im.svg";
import ProjectFourImage from "../../assets/images/Construction/Supply-Road-Project/Thumbnail.jpg";
import ProjectFiveImage from "../../assets/images/Construction/Batakundi-Naran-Hotel-Project/thumnail.jpg";
import { Link } from "react-router-dom";
import UserReviews from "../MaintenanceComponents/UserReviews";

function ConstructionComponent() {
  const [showMore, setShowMore] = useState(false);
  const projects = [
    {
      id: 1,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "CONSTRUCTION",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 2,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "CONSTRUCTION",
      category: "Batakundi Naran Hotel Project",
      image: ProjectFiveImage,
      title: "Batakundi Naran Hotel Project",
      description:
        "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
    },
  ];
  const serviceItems = [
    {
      title: "Commercial Fit-Outs",
      imgUrl: CommercialFitOut,
      description:
        "Preparing commercial spaces for tenants or businesses by customizing the interior to their needs.",
      id: 1,
    },
    {
      title: "Additions and Extensions",
      imgUrl: Extension,
      description:
        "Expanding existing structures by adding rooms, floors, or additional space.",
      id: 2,
    },
    {
      title: "Interior Build-Outs",
      imgUrl: InteriorBuildOut,
      description:
        "Customizing interior spaces in commercial buildings for specific tenant requirements.",
      id: 3,
    },
    {
      title: "Foundation and Structural Work",
      imgUrl: ServiceImg2,
      description:
        "Providing foundational and structural services for both residential and commercial projects.",
      id: 4,
    },
    {
      title: "Concrete Work",
      imgUrl: ServiceImg3,
      description:
        "Services such as concrete pouring, finishing, and repair for foundations, driveways, and more.",
      id: 5,
    },
    {
      title: "Landscaping & Outdoor Construction",
      imgUrl: ServiceImg4,
      description:
        "Designing and building outdoor spaces, including patios and landscaping.",
      id: 6,
    },
    {
      title: "Energy-Efficient Building",
      imgUrl: ServiceImg5,
      description:
        "Specializing in green or energy-efficient construction, using sustainable materials and energy-saving technologies.",
      id: 7,
    },
    {
      title: "Project Management & Consultation",
      imgUrl: ServiceImg6,
      description:
        "Offering project management and consulting services to help clients plan and execute their construction projects.",
      id: 8,
    },
    {
      title: "Demolition Services",
      imgUrl: ServiceImg7,
      description:
        "Safely demolishing existing structures for new construction or renovation.",
      id: 9,
    },
  ];
  const userReviews = [
    {
      description:
        "“Kardaan truly went above and beyond in building my dream home. From the initial planning stages to the final touches, their expertise shone through. Their clear communication made the process stress-free, and the craftsmanship is second to none. Thank you, Kardaan, for making my vision a reality!”",
      name: "Mr Khurram ( Abbottabad )",
    },
    {
      description:
        "“Outstanding service provided by Kardaan team during the construction of my home. From start to finish, Kardaan professionalism, expertise, and attention to detail were truly exceptional. Kardaan team clear communication and transparency throughout the project ensured that I was always well-informed and confident in the process. The quality of workmanship demonstrated by your team surpassed my expectations, resulting in a home that perfectly reflects my vision. Thanks”",
      name: "Malik Shoukat Ali ( Abbottabad)",
    },

    {
      description:
        "“Working with Kardaan was a pleasure from start to finish. Their professionalism and attention to detail set them apart. I always felt well-informed and involved in the process, thanks to their clear communication. The quality of work is outstanding, and I'm thrilled with the final result. Highly recommended! ”",
      name: "Obaid Ullah ( Abbottabad)",
    },
  ];
  const isWideScreen = window.innerWidth >= 1024;
  return (
    <div>
      <div className="construction-hero-section overflow-hidden border-t md:h-[485px] sm:h-[425px] h-[375px]">
        <div className="maintenance-main bg-white flex justify-center items-center p-8 md:p-0">
          <div className="w-[100%] md:w-[50%] lg:w-3/5 xl:w-3/5 flex flex-col items-start text-left md:items-start md:text-left sm:pl-10 md:pl-8 lg:pl-32">
            <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
              Let us
            </span>
            <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[41.28px] lg:text-[49.28px] ">
              build your dream
            </span>
            <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[41.28px] lg:text-[49.28px] ">
              House
            </span>

            <p className="text-[#282828] opacity-[0.50] text-[20px] sm:text-[22px] md:text-[20px] lg:text-[24px] font-normal leading-[1.3] mt-[20px] lg:mt-[27px] break-words font-SFProDisplayFont w-[85%] text-left md:text-left">
              From concept to completion, our skilled team will breathe new life
              into your home or office through our top-notch renovation
              solutions.
            </p>
            <div className="mt-[20px] lg:mt-[27px]">
              <Link to="/quote-form">
                <Button
                  bgColor="bg-[#282828]"
                  textColor="text-white"
                  className=" border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
                >
                  {" "}
                  GET OUR SERVICE
                </Button>
              </Link>
            </div>
          </div>
          <div className="hidden md:block  md:w-[50%] lg:w-2/5 xl:w-2/5 h-[485px]">
            <img
              src={HeroImg}
              className={`block h-full w-full ${
                isWideScreen ? "" : "object-cover"
              } md:object-cover `}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="min-w-full bg-[#efefef] pt-8 pb-12">
        <div className="w-[96%] mx-auto">
          <h2 className="text-center text-[color:black] text-[36px] font-SFProDisplayFont font-bold leading-[2] after:content-[''] sm:after:w-[8.5%] after:w-[12%] after:m-auto after:mt-[10px] after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
            Our Services
          </h2>
          <div className="flex mt-8 sm:mt-[40px] md:mt-[2.25rem] gap-4 justify-center flex-wrap">
            <div className="flex flex-col gap-4 lg:flex-col md:flex-row">
              <ServiceItem
                className="h-full max-w-lg md:max-w-sm lg:max-w-sm flex-wrap"
                imgUrl={ServiceImg1}
                heading="New Home Construction"
                content="Building new residential homes from scratch"
                headingSize="md:text-[24px] md:leading-[26px] leading-[28px] sm:text-[25px] text-[22px]"
              ></ServiceItem>

              <ServiceItem
                className="h-full max-w-lg md:max-w-sm lg:max-w-sm"
                imgUrl={ServiceImg9}
                heading="Commercial Construction"
                content="Constructing commercial buildings such as offices, retail spaces, restaurants, and warehouses."
                headingSize="md:text-[24px] md:leading-[26px] leading-[28px] sm:text-[25px] text-[22px]"
              ></ServiceItem>
            </div>

            <div className="hidden lg:block">
              <img
                src={ServiceImg}
                className="block max-w-[23.8rem] h-full object-cover"
                alt=""
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 xl:flex-col">
              <ServiceItem
                className="max-w-lg md:max-w-sm lg:max-w-sm"
                imgUrl={ServiceImg8}
                heading="Custom Home Construction"
                content="Creating unique, tailor-made homes based on client specifications."
                headingSize="md:text-[24px] md:leading-[26px] leading-[28px] sm:text-[25px] text-[22px]"
                contentSize="text-[15.38px]"
              ></ServiceItem>

              <ServiceItem
                className="max-w-lg md:max-w-sm lg:max-w-sm"
                imgUrl={ServiceImg10}
                heading="Residential Renovations"
                content="Renovating and remodeling existing homes, including kitchens, bathrooms, and whole-house renovations."
                headingSize="md:text-[24px] md:leading-[26px] leading-[28px] sm:text-[25px] text-[22px]"
                contentSize="text-[15.38px]"
              ></ServiceItem>
            </div>
          </div>

          <div className="flex flex-wrap w-full mt-4 gap-4 justify-center ">
            {showMore
              ? serviceItems.map((item) => (
                  <div key={item.title}>
                    <ServiceItem
                      className="max-w-lg md:max-w-sm lg:max-w-sm"
                      imgUrl={item.imgUrl}
                      heading={item.title}
                      content={item.description}
                      headingSize="md:text-[24px] md:leading-[26px] leading-[28px] sm:text-[25px] text-[22px]"
                      contentSize="text-[15.38px]"
                    ></ServiceItem>
                  </div>
                ))
              : null}
          </div>
          <div className="text-center mt-[0.3rem]">
            <Button
              // bgColor="bg-white"
              textColor="text-black"
              className="border-[4px] border-[#2A2A2A] bg-transparent lg:px-[55px] lg:py-[10px] md:px-[50px] px-[45px] py-[10px] uppercase mt-8 font-SFProDisplayFont text-base font-semibold hover:bg-[color:#2a2a2a] hover:text-[color:white]"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "View All"}
            </Button>
          </div>
        </div>
      </div>
      <div>
        <About
          showDownloadButton={false}
          className="md:flex-row-reverse"
          showHeaderVertical={true}
          imgUrl={AboutConstruction}
          heading="About Us"
          capitalizeCase={false}
        >
          <div>
            <p className="text-[15px] leading-[26px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 text-justify">
              Order through Kardaan App, reach out to your nearby handyman and
              get your work done without any hassle!
            </p>
            <p className="text-[15px] leading-[27px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 text-justify">
              Kardaan is a Farsi word used to describe a handyman or a skilled
              person. In the world of digitization, our blue collar labor has
              been left behind, Kardaan aims to empower them so that they can
              earn for themselves in a more respectable manner and provide for
              their families. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit.
            </p>
            <p className="text-[15px] leading-[26px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 pt-4 text-justify">
              HELP US CHANGE LIVES …{" "}
              {/* <span className="text-[#ffb400]">Read more</span> */}
            </p>
          </div>
        </About>
      </div>
      <UserReviews
        heading="What our Clients are saying?"
        bgColor={false}
        reviews={userReviews}
      />
      <div className="min-w-full bg-[#efefef] pb-1">
        <div className="w-[85%] mx-auto">
          <h2 className="text-center text-[color:black] text-[36px] font-SFProDisplayFont font-bold leading-[2] after:content-[''] sm:after:w-[8.5%] after:w-[12%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
            Our Projects
          </h2>
          <div className="mt-[1.5rem]">
            <ProjectItem projects={projects} showButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConstructionComponent;
