import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PopupSlider from "./PopupSlider";
import AQ2 from "../assets/images/Elevator-Images/AQ2.jpg";
import AQ3 from "../assets/images/Elevator-Images/AQ3.jpg";
import AQ4 from "../assets/images/Elevator-Images/AQ4.jpg";
import PM1 from "../assets/images/Elevator-Images/PM1.jpg";
import PM2 from "../assets/images/Elevator-Images/PM2.jpg";
import PM3 from "../assets/images/Elevator-Images/PM3.jpg";
import PM4 from "../assets/images/Elevator-Images/PM4.jpg";
import PM5 from "../assets/images/Elevator-Images/PM5.jpg";
import IBC1 from "../assets/images/Elevator-Images/IBC1.jpg";
import IBC2 from "../assets/images/Elevator-Images/IBC2.jpg";
import IBC3 from "../assets/images/Elevator-Images/IBC3.jpg";
import IBC4 from "../assets/images/Elevator-Images/IBC4.jpg";
import IqbalSite1 from "../assets/images/Construction/Supply-Road-Project/1.jpg";
import IqbalSite2 from "../assets/images/Construction/Supply-Road-Project/2.jpg";
import IqbalSite3 from "../assets/images/Construction/Supply-Road-Project/3.jpg";
import IqbalSite4 from "../assets/images/Construction/Supply-Road-Project/4.jpg";
import IqbalSite5 from "../assets/images/Construction/Supply-Road-Project/5.jpg";
import IqbalSite6 from "../assets/images/Construction/Supply-Road-Project/6.jpg";
import IqbalSite7 from "../assets/images/Construction/Supply-Road-Project/7.jpg";
// import IqbalSite8 from "../assets/images/Construction/Supply-Road-Project/8.jpg";
import NaranHotel1 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/1.jpg";
import NaranHotel2 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/2.jpg";
import NaranHotel3 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/3.jpg";
import NaranHotel4 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/4.jpg";
import NaranHotel5 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/5.jpg";
import NaranHotel6 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/6.jpg";
import NaranHotel7 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/7.jpg";
import NaranHotel8 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/8.jpg";
import NaranHotel9 from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/9.jpg";

const ProjectItem = ({
  projects = [],
  showButton = false,
  columnGap = "gap-8",
}) => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const isProjectsPage = location.pathname === "/projects";
  const projectsImages = [
    {
      id: 1,
      type: "video",
      videoUrl: "https://www.youtube.com/embed/ZlqOi6XoeKk?si=ACLLzT34wNQC9tgF",
      title:
        "Elevator Installation at Abul Qasim Super Market Bahria Town Karachi",
      category: "Abu Qasim",
    },
    {
      id: 2,
      type: "image",
      image: AQ2,
      title:
        "Elevator Installation at Abul Qasim Super Market Bahria Town Karachi",
      category: "Abu Qasim",
    },
    {
      id: 3,
      type: "image",
      image: AQ3,
      title:
        "Elevator Installation at Abul Qasim Super Market Bahria Town Karachi",
      category: "Abu Qasim",
    },
    {
      id: 4,
      type: "image",
      image: AQ4,
      title:
        "Elevator Installation at Abul Qasim Super Market Bahria Town Karachi",
      category: "Abu Qasim",
    },
    {
      id: 5,
      type: "image",
      image: PM1,
      title:
        "Elevator Installation at Prime Medical College, Near 26 Num, Islamabad",
      category: "Prime Medical",
    },
    {
      id: 6,
      type: "image",
      image: PM2,
      title:
        "Elevator Installation at Prime Medical College, Near 26 Num, Islamabad",
      category: "Prime Medical",
    },
    {
      id: 7,
      type: "image",
      image: PM3,
      title:
        "Elevator Installation at Prime Medical College, Near 26 Num, Islamabad",
      category: "Prime Medical",
    },
    {
      id: 8,
      type: "image",
      image: PM4,
      title:
        "Elevator Installation at Prime Medical College, Near 26 Num, Islamabad",
      category: "Prime Medical",
    },
    {
      id: 9,
      type: "image",
      image: PM5,
      title:
        "Elevator Installation at Prime Medical College, Near 26 Num, Islamabad",
      category: "Prime Medical",
    },
    {
      id: 10,
      type: "image",
      image: IBC1,
      title:
        "Elevator Installation at Infinity Business Center, Mumtaz City, Islamabad",
      category: "IBC City",
    },
    {
      id: 11,
      type: "image",
      image: IBC2,
      title:
        "Elevator Installation at Infinity Business Center, Mumtaz City, Islamabad",
      category: "IBC City",
    },
    {
      id: 12,
      type: "image",
      image: IBC3,
      title:
        "Elevator Installation at Infinity Business Center, Mumtaz City, Islamabad",
      category: "IBC City",
    },
    {
      id: 13,
      type: "image",
      image: IBC4,
      title:
        "Elevator Installation at Infinity Business Center, Mumtaz City, Islamabad",
      category: "IBC City",
    },
    {
      id: 14,
      type: "video",
      videoUrl: "https://www.youtube.com/embed/heK53VlJSoE",
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 14,
      type: "image",
      image: IqbalSite1,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 15,
      type: "image",
      image: IqbalSite2,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 16,
      type: "image",
      image: IqbalSite3,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 17,
      type: "image",
      image: IqbalSite4,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 18,
      type: "image",
      image: IqbalSite5,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 19,
      type: "image",
      image: IqbalSite6,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 20,
      type: "image",
      image: IqbalSite7,
      title: "Home Construction Site Iqbal Road Supply Abbottabad",
      category: "Iqbal Road Site",
    },
    {
      id: 21,
      type: "video",
      videoUrl: "https://www.youtube.com/embed/X8D91B5Lc_c",
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 22,
      type: "image",
      image: NaranHotel1,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 23,
      type: "image",
      image: NaranHotel2,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 24,
      type: "image",
      image: NaranHotel3,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 25,
      type: "image",
      image: NaranHotel4,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 26,
      type: "image",
      image: NaranHotel5,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 27,
      type: "image",
      image: NaranHotel6,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 28,
      type: "image",
      image: NaranHotel7,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 29,
      type: "image",
      image: NaranHotel8,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
    {
      id: 30,
      type: "image",
      image: NaranHotel9,
      title: "Hotel Construction Project at Batakundi, Naran",
      category: "Batakundi Naran Hotel Project",
    },
  ];
  const renderProjects = () => {
    if (projects.length === 0) {
      return <p className="m-auto mb-4 text-xl">No Projects Listed</p>;
    }

    return projects.map((project, index) => (
      <div
        key={index}
        className={`project${project.id} sm:w-[350px] w-[290px] flex flex-col sm:mb-0 mb-2 md:w-[47%] xl:w-[31%]`}
      >
        <div className={`project-img-${project.id} h-[220px] `}>
          <img
            className="w-full object-cover h-full"
            src={project.image}
            alt={`Project ${project.title} Images`}
          />
        </div>
        <div className="project-description border-[1.11px] border-solid border-[#666666] px-[20px] pt-[20px] pb-[28px] mt-[7px] md:h-[350px]">
          <p className="title-para text-[color:var(--sub-heading-color)] font-OpenSansFont text-[16.65px] font-bold leading-[1.9] tracking-[1px] break-words mx-0">
            {project.title}
          </p>
          <p className="description-para font-OpenSansFont text-[#666666] text-base font-normal leading-relaxed tracking-wide break-words">
            {project.description}
          </p>
          <div className="learn-more font-RubikFont mt-[30px] text-[#666666] text-[12.21px] font-bold leading-relaxed tracking-wide break-words">
            <button
              className="learn-btn font-RubikFont cursor-pointer lg:px-[50px] lg:py-[10px] md:px-[30px] px-[35px] py-[10px] bg-transparent border-[1.11px] border-solid border-[#666666] text-[#666666] text-[12.21px] font-bold tracking-[1.22px] break-words hover:bg-[#2a2a2a] hover:text-white active:border-none"
              onClick={() => {
                setSelectedCategory(project.category);
                setShowModal(true);
              }}
            >
              VIEW PROJECT
            </button>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div
        className={`projects-list flex flex-wrap justify-center mt-[20px] ${columnGap}`}
      >
        {renderProjects()}
      </div>
      {showModal && (
        <PopupSlider
          onClose={() => setShowModal(false)}
          projects={projectsImages.filter(
            (project) => project.category === selectedCategory
          )}
        />
      )}
      {!isProjectsPage && showButton && projects.length > 0 && (
        <div className="view-all-btn flex items-center justify-center my-[50px] w-full">
          <Link
            // Add the category as a parameter to the URL
            to={`/projects/${
              projects.length > 0 ? projects[0].itemText.toLowerCase() : ""
            }`}
          >
            <button className="border-[4px] border-solid border-[color:var(--button-border-color)] text-[color:var(--button-border-color)] cursor-pointer px-[60px] py-[8px] bg-transparent text-[20px] font-bold tracking-[0.60px] break-words hover:bg-[color:#2a2a2a] hover:text-[color:white]">
              VIEW ALL
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

export default ProjectItem;
