import React from "react";
import AboutUsImage from "../../assets/images/AboutUs-Images/About-us-img.svg";

const About = () => {
  return (
    <>
      <div
        className="about-main w-full flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col"
        id="home-about"
      >
        <div className="about-img w-full 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-full sm:w-full sm:h-auto h-[360px]">
          <img
            className="2xl:h-full xl:h-full lg:h-full sm:h-[330px] w-full h-full object-cover"
            src={AboutUsImage}
            alt=""
          />
        </div>
        <div className="about-text w-full 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-full sm:w-full bg-[#f7f7f7] flex flex-col justify-between">
          <div
            className="upper-section flex flex-col justify-between lg:p-[75px] p-[30px]
          xl:flex-row
          lg:flex-row
          md:flex-row
          sm:flex-col  sm:py-11 "
          >
            <div className="left w-full lg:full md:w-full sm:w-full md:mr-[25px]">
              <h2 className="text-[var(--sub-heading-color)] p-0 text-[40px] font-bold font-RobotoFlexFont after:content-[''] after:w-[15%] after:h-[5px] after:bg-[var(--mycolor)] after:block after:mt-5 pb-[15px] mr-[10px]">
                About us
              </h2>
              <div className="paragraph-left mt-[15px] text-[#666666] 2xl:text-[20px] text-[15px] font-normal  leading-[2] break-words ">
                <p className="font-SFProDisplayFont text-left">
                  Welcome to Kardaan, where craftsmanship meets innovation!
                  Derived from the Persian word meaning "handyman" or "skilled
                  worker," Kardaan epitomizes a commitment to excellence in
                  construction, renovation, maintenance, material supply,
                  elevator and lift installation, and solar installation
                  services. Our cutting-edge approach allows users to
                  effortlessly order maintenance services through our
                  user-friendly app, ensuring prompt and reliable assistance
                  delivered straight to their doorstep. With over four years of
                  unwavering dedication. We have established ourselves as a
                  trusted name in the industry.
                </p>
              </div>
            </div>
            <div className="right  w-full lg:w-full md:w-full sm:w-full pt-[34px] 2xl:pt-[115px] lg:pt-[115px] md:pt-[114px] sm:pt-[30px] flex flex-col justify-between">
              <p className="font-SFProDisplayFont text-[#666666] 2xl:text-[20px] text-[15px] font-normal leading-[2] break-words text-left">
                Transforming visions into reality with our high-skilled team. At
                Kardaan, we don't just provide services; we build partnerships
                in the journey of creating, maintaining, and enhancing spaces
                that uniquely reflect your vision. Every project is a
                masterpiece, and every client is a valued collaborator in our
                pursuit of excellence. Our track record of successfully
                completed projects stands as a testament to our proficiency and
                the satisfaction of our clients. Choose Kardaan – where
                tradition meets technology, and the standard is nothing short of
                exceptional.
              </p>
            </div>
          </div>
          <div className="lower-section sm:h-[110px] w-full flex sm:flex-row flex-col">
            <div className="years bg-[color:var(--mycolor)] w-full sm:w-full flex flex-col justify-center items-center p-2">
              <h3 className="text-[40px] font-bold">05</h3>
              <p className="text-xs font-bold  tracking-[0.24px]">
                YEARS ESTABLISHED
              </p>
            </div>
            <div className="projects bg-[color:var(--sub-heading-color)] w-full sm:w-full flex flex-col justify-center items-center text-[white] p-2">
              <h3 className="text-[40px] font-bold">250</h3>
              <p className="text-xs font-bold  tracking-[0.24px]">
                COMPLETED PROJECTS
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
