import React from "react";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className="lg:main-construction lg:flex lg:flex-row lg:w-full ">
      <div
        className="construction bg-constructionImage w-full bg-cover bg-center bg-no-repeat flex justify-center z-0 relative  before:content-[''] before:absolute before:top-0 before:right-0 before:w-full before:h-full before:bg-[#1f1f1fdc] before:z-[1] 
        h-[300px] 2xl:h-[591px] xl:h-[491px] lg:h-[491px] md:h-[491px] sm:h-[350px] "
      >
        <div className="inner-content flex flex-col justify-center items-center w-full lg:pl-0 lg:pb-0 md:pl-0  md:pr-[10%]">
          <h1
            className=" text-white font-SFProDisplayFont font-bold capitalize break-words tracking-[1.0px] leading-12 w-[96%] xl:m-0 sm:m-0 pl-8 relative z-[999!important] before:content-[''] before:w-[12px] before:bg-[var(--mycolor)] before:absolute before:top-0 before:left-0 before:h-full text-[23px]
            2xl:text-5xl xl:text-5xl lg:text-[39px] xl:w-[77%] lg:w-[68%] md:w-[67%] md:text-4xl sm:w-[85%]  
            "
          >
            Building Trusts,
            <span className="sm:block md:inline lg:block">
              Elevating Standards
            </span>
          </h1>
          <p
            className=" hero-section-para relative mt-[15px] font-SFProDisplay font-normal tracking-wide text-white z-[999!important] 
            2xl:w-[70%] xl:w-[68.5%] lg:w-[56.5%] md:w-[58%] w-[81%] text-[13.8px] 2xl:text-[18px]
            sm:ml-0 sm:mt-[15px] lg:mt-[25px] sm:w-[76%]  
          "
          >
            Precision, Passion and Perfection -
            <span className="sm:block md:inline block">
              {" "}
              Our Signature in Every Service.
            </span>
          </p>
          <div className="button-box w-[96%] xl:w-[77%] lg:w-[65%] md:w-[67%] sm:w-[85%] sm:mt-[5%] mt-3 md:mt-[7%]">
            <Link to="/projects/all">
              <button
                className="
            bg-[var(--mycolor)] text-[color:#282828] font-SFProDisplayFont text-sm font-bold tracking-[1.40px] border-[3px] border-[color:#ffb400] text-center cursor-pointer relative z-[999!important] 2xl:px-[50px] 2xl:py-[16px] xl:px-[50px] xl:py-[16px] lg:px-[50px] lg:py-[16px] md:py-[14px] md:px-[35px] sm:px-[23px] sm:py-[12px]  px-[20px] py-[8px] hover:text-[color:#ffb400] hover:bg-transparent hover:border-[3px] hover:border-[color:#ffb400] hover:border-solid
          "
              >
                VIEW OUR WORK
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
