/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import MaterialItem from "../components/Construction/MaterialItem";
import ConstructionHeroImage from "../assets/images/Construction/ConstructionHeroImage.svg";
import Button from "../components/Button";

import MaterialItem1 from "../assets/images/Construction/MaterialItem1.svg";
import MaterialItem2 from "../assets/images/Construction/MaterialItem2.svg";
import MaterialItem3 from "../assets/images/Construction/MaterialItem3.svg";
import MaterialItem4 from "../assets/images/Construction/MaterialItem4.svg";
import MaterialItem5 from "../assets/images/Construction/MaterialItem5.svg";
import MaterialItem6 from "../assets/images/Construction/MaterialItem6.svg";
import MaterialItem7 from "../assets/images/Construction/MaterialItem7.png";
import MaterialItem8 from "../assets/images/Construction/MaterialItem8.svg";
import MaterialItem9 from "../assets/images/Construction/MaterialItem9.svg";
import MaterialItem10 from "../assets/images/Construction/MaterialItem10.svg";
import MaterialItem11 from "../assets/images/Construction/MaterialItem11.svg";
import MaterialItem12 from "../assets/images/Construction/MaterialItem12.svg";
import MaterialItem13 from "../assets/images/Construction/MaterialItem13.svg";
import MaterialItem14 from "../assets/images/Construction/bathroom.png";
import MaterialItem15 from "../assets/images/Construction/Tiles.png";
import MaterialItem16 from "../assets/images/Construction/Stones.png";

import ProjectItem from "../components/ProjectItem";
// Projects Import's
import ProjectImage1 from "../../src/assets/images/Elevator-Images/AQ4.jpg";
import ProjectThreeImage from "../../src/assets/images/Elevator-Images/PM1.jpg";
import ProjectTwoImage from "../../src/assets/images/Elevator-Images/IBC2.jpg";
import ProjectFourImage from "../assets/images/Construction/Supply-Road-Project/Thumbnail.jpg";
import ProjectFiveImage from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/thumnail.jpg";

function ConstructionMaterial() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [aciveTab, setActiveTab] = useState("ALL_MATERIAL");
  const [constructionItems, setConstructionItem] = useState([]);
  const constructionsItems = [
    {
      title: "Steel (Rebar)",
      description: "Used for reinforcing concrete structures.",
      imgUrl: MaterialItem1,
      category: "STRUCTURAL_MATERIAL",
    },
    {
      title: "Cement",
      description: "Essential for making concrete.",
      imgUrl: MaterialItem2,
      category: "STRUCTURAL_MATERIAL",
    },
    {
      title: "Sand",
      description: "Used in concrete and mortar.",
      imgUrl: MaterialItem3,
      category: "AGGREGATES_MATERIAL",
    },
    {
      title: "Gravel (Crushed Stone)",
      description: "Provides bulk and stability to concrete.",
      imgUrl: MaterialItem4,
      category: "AGGREGATES_MATERIAL",
    },
    {
      title: "Electric Cables",
      description: "Conductors for electrical power distribution.",
      imgUrl: MaterialItem5,
      category: "ELECTRICAL_MATERIAL",
    },
    {
      title: "Electric Conduits",
      description: "Used to protect and route electrical wiring.",
      imgUrl: MaterialItem6,
      category: "ELECTRICAL_MATERIAL",
    },
    {
      title: "Plumbing Sewerage Lines (PVC Pipes)",
      description: "For sewage and drainage systems.",
      imgUrl: MaterialItem7,
      category: "PLUMBING_MATERIAL",
    },
    {
      title: "Plumbing Gas & Water Lines (PPR Pipes)",
      description: "Used for water and gas distribution.",
      imgUrl: MaterialItem8,
      category: "PLUMBING_MATERIAL",
    },
    {
      title: "Bathroom Accessories & CP Sets",
      description: "Fixtures for bathrooms, including faucets and showerheads.",
      imgUrl: MaterialItem14,
      category: "PLUMBING_MATERIAL",
    },
    {
      title: "Kitchen Equipment",
      description: "Appliances and fixtures for kitchens",
      imgUrl: MaterialItem9,
      category: "KITCHEN_MATERIAL",
    },
    {
      title: "House Furniture",
      description: "Various furniture for homes.",
      imgUrl: MaterialItem10,
      category: "FURNITURE_FIXTURES",
    },
    {
      title: "Plywood Sheets",
      description: "Used for cabinetry, furniture, and more.",
      imgUrl: MaterialItem11,
      category: "FURNITURE_FIXTURES",
    },
    {
      title: "Wooden Doors",
      description: "Doors made of wood.",
      imgUrl: MaterialItem12,
      category: "DOORS_ENTRYWAYS",
    },
    {
      title: "Fiber Doors",
      description: "Doors made of fiberglass or composite materials.",
      imgUrl: MaterialItem13,
      category: "DOORS_ENTRYWAYS",
    },
    {
      title: "Tiles",
      description: "Used for flooring and walls.",
      imgUrl: MaterialItem15,
      category: "FLOORING_WALL",
    },
    {
      title: "Marble and Granite",
      description: "Premium materials for flooring and countertops.",
      imgUrl: MaterialItem16,
      category: "FLOORING_WALL",
    },
  ];

  const tabItems = [
    {
      title: "All Materials",
      tab: "ALL_MATERIAL",
    },
    {
      title: "Structural Materials",
      tab: "STRUCTURAL_MATERIAL",
    },
    {
      title: "Aggregates",
      tab: "AGGREGATES_MATERIAL",
    },
    {
      title: "Electrical Materials",
      tab: "ELECTRICAL_MATERIAL",
    },
    {
      title: "Plumbing Materials",
      tab: "PLUMBING_MATERIAL",
    },
    {
      title: "Kitchen Materials",
      tab: "KITCHEN_MATERIAL",
    },
    {
      title: "Doors & Entryways",
      tab: "DOORS_ENTRYWAYS",
    },
    {
      title: "Furniture & Fixtures",
      tab: "FURNITURE_FIXTURES",
    },
    {
      title: "Flooring & Wall Materials",
      tab: "FLOORING_WALL",
    },
  ];

  const projects = [
    {
      id: 1,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ALL",
      category: "Abu Qasim",
      image: ProjectImage1,
      title: "Abul Qasim Super Market Bahria Town Karachi",
      description:
        "Discover elevated shopping at AQ Super Market, Bahria Town Karachi, with modern elevators by Kardaan Labour, ensuring convenience as you explore our diverse range effortlessly.",
    },
    {
      id: 2,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ALL",
      category: "Prime Medical",
      image: ProjectThreeImage,
      title: "Prime Medical College, Near 26 Num, Islamabad",
      description:
        "Elevators, expertly installed by Kardaan Labour's team, now grace Prime Medical College in Islamabad, enhancing accessibility and ensuring a smooth, efficient environment for all.",
    },
    {
      id: 3,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "ALL",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 4,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ALL",
      category: "IBC City",
      image: ProjectTwoImage,
      title: "Infinity Business Center, Mumtaz City, Islamabad",
      description:
        "Experience elevated living in IBC Mumtaz City, Islamabad, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
    },
    {
      id: 5,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "ALL",
      category: "Batakundi Naran Hotel Project",
      image: ProjectFiveImage,
      title: "Batakundi Naran Hotel Project",
      description:
        "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
    },
  ];

  useEffect(() => {
    if (!aciveTab || aciveTab === "ALL_MATERIAL") {
      setConstructionItem(() => [...constructionsItems]);
    } else {
      const filterData = constructionsItems.filter(
        (item) => item.category === aciveTab
      );
      setConstructionItem([...filterData]);
    }
  }, [aciveTab, setConstructionItem]);

  return (
    <div>
      {/* hero */}
      <div className="w-full bg-white flex items-center justify-center min-h-[524px] lg:min-h-[490px] border-t ">
        <div className="w-[100%] md:w-3/6 flex flex-col items-start text-left pl-10 md:items-start md:text-left md:pl-24">
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[41.28px] lg:text-[49.28px] ">
            We are the
          </span>
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[41.28px] lg:text-[49.28px] ">
            building material
          </span>
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[41.28px] lg:text-[49.28px] ">
            suppliers
          </span>

          <p className="text-[#282828] opacity-[0.50] text-[22px] md:text-[20px] lg:text-[24px] font-normal leading-[1.3] mt-[27px] break-words font-SFProDisplayFont w-[85%] text-left md:text-left">
            From concept to completion, our skilled team will breathe new life
            into your home or office through our top-notch renovation solutions.
          </p>
          <Link to="/quote-form">
            <div className="mt-[27px]">
              <Button
                bgColor="bg-[#282828]"
                textColor="text-white"
                className="px-6 py-2 border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
              >
                {" "}
                GET OUR SERVICE
              </Button>
            </div>
          </Link>
        </div>
        <div className="hidden md:block md:w-3/6 bg-[#FFB400]">
          <img
            className="w-full h-auto object-cover"
            src={ConstructionHeroImage}
            alt=""
          />
        </div>
      </div>
      {/* hero */}

      {/* tabs */}
      {/* <div className="w-full bg-[color:#efefef]">
        <div className="w-[98%] mx-auto pt-[25px] flex justify-center items-stretch gap-2 flex-wrap">
          {tabItems.map((item) => (
            <Button
              onClick={() => setActiveTab(item.tab)}
              key={item.title}
              className={` py-3 px-5 w-[250px] items-stretch ${item.tab === aciveTab ? "bg-[color:#2a2a2a] border-none" : "bg-[#efefef] border-[2px] border-solid border-[color:#666666]"}`}
              textColor={`${item.tab === aciveTab ? "text-white" : "text-[color:#666666]"}`}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </div> */}
      <div className="w-full bg-[color:#efefef]">
        <div className="w-[98%] mx-auto pt-[25px] flex justify-center items-stretch gap-2 flex-wrap">
          {tabItems.map((item) => (
            <Button
              onClick={() => setActiveTab(item.tab)}
              key={item.title}
              className={`py-3 px-5 w-full sm:w-[250px] items-stretch
          ${
            item.tab === aciveTab
              ? "bg-[color:#2a2a2a] border-none"
              : "bg-[#efefef] border-[2px] border-solid border-[color:#666666]"
          }
        `}
              textColor={`${
                item.tab === aciveTab ? "text-white" : "text-[color:#666666]"
              }`}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </div>

      {/* tabs */}

      <div className="w-full bg-[#efefef] py-12">
        <div className="w-[95%] mx-auto">
          <div className="w-full flex flex-wrap gap-6  justify-center">
            {constructionItems?.map((item) => (
              <div key={item.title}>
                <MaterialItem
                  title={item.title}
                  description={item.description}
                  imgURl={item.imgUrl}
                ></MaterialItem>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="min-w-full bg-[#efefef] pb-[1px]">
        <div className="w-[80%] mx-auto">
          <h2 className="text-center text-[color:black] text-[36px] font-SFProDisplayFont font-bold leading-[2] after:content-[''] after:w-[8.5%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
            Our Projects
          </h2>
          <div className="mt-[1.5rem]">
            <ProjectItem projects={projects} showButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConstructionMaterial;
