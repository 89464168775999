import React from "react";
import ReactPlayer from "react-player";
import KardaanVideo from "../../assets/videos/Kardaan.mp4";
// import "~video-react/dist/video-react.css"; // import css
// import { Player } from "video-react";
// import ShowcaseVideo from "../../assets/images/Showcase-Images/showcase-video.png";
// import { PlayCircle } from "lucide-react";

const ProjectShowcase = () => {
  const showcaseData = [
    {
      title: "Expertise & Experience",
      description: "Our seasoned professionals master every detail.",
    },
    {
      title: "Complete Solutions",
      description: "One-stop for maintenance, materials, and more.",
    },
    {
      title: "Quality Unleashed",
      description: "Uncompromising standards, unparalleled results.",
    },
    {
      title: "We Build Trust",
      description: "Clients are family, and your trust is our cornerstone.",
    },
  ];

  return (
    <div
      className="project-showcase flex flex-col lg:flex-row md:flex-col sm:flex-col w-full"
      id="home-projects"
    >
      {/* Left Section */}
      <div className="showcase-text bg-[var(--sub-heading-color)] sm:pt-16 pt-8 pb-[44px] lg:pl-[40px] lg:w-[37%] md:w-full h-auto text-[white]">
        <h2
          className="text-[white] text-center text-[35px] lg:text-[40px]  font-bold leading-[1.2] break-words  after:content-[''] after:block after:w-[13%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:mt-[50px] 
        lg:after:w-[15%] lg:after:text-left lg:after:m-0 lg:after:mt-[30px]
        sm:after:m-auto sm:after:mt-[15px] sm:after:w-[14%] sm:after:text-center sm:text-[40px] sm:text-center lg:text-left md:text-[40px] md:text-center"
        >
          Why Choose Kardaan?
        </h2>
        <div className="flex flex-col flex-wrap lg:text-left lg:flex-col lg:justify-start md:flex-row md:flex-wrap md:justify-evenly sm:text-center w-full  text-center pt-10">
          {showcaseData.map((item, index) => (
            <div
              key={index}
              className="
            lg:flex lg:flex-col lg:items-start lg:px-0 lg:mb-0
            xl:w-[78%] lg:w-[82%] md:w-[34%] w-full md:mb-6 pb-4 mt-0 md:mt-10 lg:mt-0"
            >
              <p className="my-title text-[18px] 2xl:text-[25px] font-bold">
                {item.title}
              </p>
              <p className="title-description w-full md:w-[100%] sm:text-[16px] 2xl:text-[19px] 2xl:w-[87%] text-[15px] font-normal">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Right Section */}

      <div className="showcase-video lg:w-[70%] md:w-full h-auto  lg:flex lg:flex-col  flex flex-col">
        {/* <img
          className="w-full h-full lg:object-cover"
          src={ShowcaseVideo}
          alt=""
        /> */}
        {/* <iframe
          width="100%"
          height="693"
          src="https://www.youtube.com/embed/guJg-LQBXU4?si=mMGnMk2q5HhHXGir"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
        <div className="lg:h-full md:h-[500px] sm:h-[350px] h-[280px] w-full">
          <ReactPlayer
            url={KardaanVideo}
            muted={true}
            // light={ShowcaseVideo}
            playing={true}
            loop={true}
            height="100%"
            width="100%"
            controls={true}
            // playIcon={<PlayCircle color="#ffb400" height={60} width={60} />}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e) => e.preventDefault()}
          />
          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0I4TBV9z_sk?si=bReUcG1bj06Rammh"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
          {/* <Player preload="auto">
            <source src="https://youtu.be/guJg-LQBXU4" />
          </Player> */}
        </div>
        <div className="showcase-footer lg:h-[110px] xl:h-[110px] 2xl:h-[110px] flex flex-col sm:justify-between md:flex-row sm:flex-col">
          <div className="call bg-[color:var(--mycolor)] lg:w-2/4 lg:flex lg:flex-col lg:justify-center lg:pl-[18px] lg:pt-[10px] md:w-[50%] flex flex-col md:items-center lg:items-start justify-center md:justify-start p-2">
            <h2 className="text-[black] text-[20px] md:text-[30px] font-bold leading-[1.7] break-words text-center md:text-left">
              Call for a Quote
            </h2>
            <p className="text-[black] text-[16px] md:text-[24px] font-medium leading-[1.7] tracking-[0.48-px] break-words text-center md:text-left">
              +92 322 5273226
            </p>
          </div>
          <div className="whatsapp lg:w-2/4 lg:flex lg:items-center lg:justify-center lg:m-[50px] md:w-[50%] flex items-center justify-center md:justify-center m-[10px] p-2">
            <a
              href="https://api.whatsapp.com/send?phone=923225273226&text=Hello!%20Can%20I%20get%20more%20info%20about%20this%3F"
              target="_blank"
              rel="noreferrer"
            >
              <button className="whatsapp-btn border-[4px] border-solid border-[color:var(--button-border-color)] text-[color:var(--button-border-color)] cursor-pointer px-[20px] md:px-[60px] py-[8px] md:py-[10px] bg-transparent text-[12px] sm:text-[20px] font-bold tracking-[0.60px] break-words hover:bg-[color:#2A2A2A] hover:text-[color:white]">
                WHATSAPP
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectShowcase;
