import WallApartment from "../assets/images/Rennovation-Images/walls-apartment-plan1.png";
import OurServices from "../components/RenovationComponents/OurServices.jsx";
import RenovationVideo from "../components/RenovationComponents/RenovationVideo.jsx";
import Button from "../components/Button.jsx";
import UserReviews from "../components/MaintenanceComponents/UserReviews.jsx";
// import ProjectItem from "../components/ProjectItem.jsx";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

function RenovationPage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const userReviews = [
    {
      description:
        "“Kardaan truly went above and beyond in building my dream home. From the initial planning stages to the final touches, their expertise shone through. Their clear communication made the process stress-free, and the craftsmanship is second to none. Thank you, Kardaan, for making my vision a reality!”",
      name: "Mr Khurram ( Abbottabad )",
    },
    {
      description:
        "“Outstanding service provided by Kardaan team during the construction of my home. From start to finish, Kardaan professionalism, expertise, and attention to detail were truly exceptional. Kardaan team clear communication and transparency throughout the project ensured that I was always well-informed and confident in the process. The quality of workmanship demonstrated by your team surpassed my expectations, resulting in a home that perfectly reflects my vision. Thanks”",
      name: "Malik Shoukat Ali ( Abbottabad)",
    },

    {
      description:
        "“Working with Kardaan was a pleasure from start to finish. Their professionalism and attention to detail set them apart. I always felt well-informed and involved in the process, thanks to their clear communication. The quality of work is outstanding, and I'm thrilled with the final result. Highly recommended! ”",
      name: "Obaid Ullah ( Abbottabad)",
    },
  ];
  // const projects = [];
  return (
    <div>
      {/* SubHero Start */}

      {/* hero */}
      <div className="w-full bg-white flex items-center justify-center min-h-[524px] lg:min-h-[490px] border-t ">
        <div className="w-[100%] md:w-3/6 flex flex-col items-start pl-10 text-left md:items-start md:text-left md:pl-24">
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            We Renovate
          </span>
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Your Home with
          </span>
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Our Heart.
          </span>

          <p className="text-[#282828] opacity-[0.50] text-[22px] md:text-[20px] lg:text-[24px] font-normal leading-[1.3] mt-[27px] break-words font-SFProDisplayFont w-[85%] text-left md:text-left">
            From concept to completion, our skilled team will breathe new life
            into your home or office through our top-notch renovation solutions.
          </p>
          <div className="mt-[27px]">
            <Link to="/quote-form">
              <Button
                bgColor="bg-[#282828]"
                textColor="text-white"
                className=" border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
              >
                {" "}
                GET OUR SERVICE
              </Button>
            </Link>
          </div>
        </div>
        <div className="hidden md:block md:w-3/6 h-[490px]">
          <img
            className="h-full w-full object-cover"
            src={WallApartment}
            alt=""
          />
        </div>
      </div>
      {/* hero */}
      {/* SubHero End */}

      {/* Services Component */}
      <OurServices />
      {/* Renovation Video */}
      <RenovationVideo />
      <UserReviews
        heading="What our Clients are saying?"
        bgColor={false}
        reviews={userReviews}
      />
      {/* Renovation Projects */}
      {/* <div className="bg-[#efefef] py-20">
        <div className="projects-main w-[85%] mx-auto">
          <h3 className="text-center m-auto text-[40px] font-bold after:content-[''] after:w-[5%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block mb-8">
            Latest Projects
          </h3>
          <ProjectItem projects={projects} showButton />
        </div>
      </div> */}
    </div>
  );
}

export default RenovationPage;
