// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ServiceItem = ({ imageUrl, title, description, icon, Frame, path }) => {
  const navigate = useNavigate();
  // console.log(path)
  return (
    <div
      className="item bg-[var(--itemcolor)] 2xl:h-[80px] h-[70px] w-full sm:mt-[10px] mt-[10px] lg:w-full sm:w-[90%] sm:m-auto flex justify-between items-center cursor-pointer md:transition-transform md:ease-in md:delay-0 md:hover:scale-[1.1] transition-transform ease-in delay-200 scale-[1]"
      onClick={() => navigate(path)}
    >
      <div className="service-icon bg-[color:#2A2A2A] w-[46.64px] h-[46.64px] flex justify-center items-center">
        <img
          className="p-[5px] w-full h-full"
          src={imageUrl}
          alt={`${icon} Icon`}
        />
      </div>
      <div className="service-content lg:w-[73%] w-[75%]">
        <h3 className="text-[14.74px] text-[color:#282828] lg:text-[14.74px]  sm:text-[17.74px] font-bold">
          {title}
        </h3>
        <p className="font-SFProDisplayFont text-[color:#282828] text-[11.5px] 2xl:text-[15.5px] xl:text-[12.0px] lg:text-[11px]  md:text-[11.50px] sm:text-[13px] font-normal leading-[1.53] opacity-[0.90] break-words">
          {description}
        </p>
      </div>
      <div className="right-arrow w-[5%] xl:w-[10%] lg:w-[9%] md-[9%] sm:w-[6%]">
        <img
          className="w-full h-full object-none cursor-pointer"
          src={Frame}
          alt=""
        />
      </div>
    </div>
  );
};
export default ServiceItem;
