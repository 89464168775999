import React from "react";
import IOSDownloadButton from "../assets/images/Download-Buttons/IOSDownloadButton.svg";
import AndroidDownloadButton from "../assets/images/Download-Buttons/AndroidDownloadButton.svg";
import { PlayCircle } from "lucide-react";
import ReactPlayer from "react-player";

function About({
  imgUrl = "",
  videoUrl = "",
  thumbnailUrl = "",
  showDownloadButton = false,
  showHeaderVertical = false,
  heading = "",
  className = "",
  children = "",
  capitalizeCase = true,
}) {
  return (
    <div className="p-8 md:p-16 w-full">
      <div className={`flex flex-col md:items-center ${className}`}>
        <div className="w-full md:w-6/12 sm:px-7 md:px-8">
          <div>
            {!showHeaderVertical && (
              <div>
                {capitalizeCase ? (
                  <h2 className="capitalize text-black text-[40px] font-bold font-SFProDisplayFont leading-[32px] p-0 m-0 pt-1">
                    {heading}
                  </h2>
                ) : (
                  <h2 className="text-[40px] text-[#ffb400] uppercase font-bold font-SFProDisplayFont leading-[32px] p-0 m-0 pt-1">
                    {heading}
                  </h2>
                )}

                <div className="border-b-[4px] border-[#ffb400] w-[48px] my-5"></div>
              </div>
            )}

            {showHeaderVertical && (
              <div className="flex">
                <div className="border-r-[5px]  border-[#ffb400] h-[71px]"></div>
                <div className="flex flex-col justify-start px-4">
                  <span className="text-[22px] md:text-[20px] lg:text-[22px] text-[#282828] leading-[32px] font-SFProDisplayFont">
                    From concept to completion.
                  </span>
                  <h2 className="text-[40px] text-[#ffb400] uppercase font-bold font-SFProDisplayFont leading-[32px] p-0 m-0 pt-1 mb-5">
                    {heading}
                  </h2>
                </div>
              </div>
            )}
          </div>
          {/* all children */}
          {children}
          {/* all children */}
          {showDownloadButton && (
            <div className="flex w-full flex-wrap md:flex-nowrap sm:justify-start  md:justify-start">
              <a
                href="https://apps.apple.com/us/app/kardaan-doorstep-services/id1444422720?ls=1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="pr-3 pt-2 md:pt-8"
                  src={IOSDownloadButton}
                  alt="Download for IOS"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=codematics.kardaan.ondemand.swiftservices"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="pr-3 pt-2 md:pt-8"
                  src={AndroidDownloadButton}
                  alt="Download for Android"
                />
              </a>
            </div>
          )}
        </div>
        <div className="w-full md:w-6/12  md:flex md:justify-center mt-8 lg:mt-0 sm:h-[345px!important] h-[240px]">
          {/* <img className="block " src={imgUrl} alt="Construction Video Thumbnail" /> */}
          {imgUrl ? (
            <img
              className="block h-full w-full"
              src={imgUrl}
              alt="Construction Video Thumbnail"
            />
          ) : (
            <ReactPlayer
              url={videoUrl}
              playing={true}
              loop={true}
              muted={true}
              width={`100%`}
              height={`100%`}
              playIcon={<PlayCircle color="#ffb400" height={50} width={50} />}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              onContextMenu={(e) => e.preventDefault()}
              controls={true}
              light={thumbnailUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
