import React, { useState } from "react";
import { Facebook, Instagram, Whatsapp, Linkedin } from "react-bootstrap-icons";
import Apple from "../../assets/images/App-Images/Apple.svg";
import Google from "../../assets/images/App-Images/Google.svg";
import Logo from "../../assets/images/App-Images/codematicsLogo.svg";
import kardaanLogo from "../../assets/images/Footer-Images/kardaan-logo.svg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
// import emailjs from "emailjs-com";
import "../../App.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }

    // Validate message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  // const sendEmail = () => {
  //   const templateParams = {
  //     from_name: formData.name,
  //     from_email: formData.email,
  //     message: formData.message,
  //   };

  //   emailjs
  //     .send(
  //       "service_sx9u94p", // Email.js service ID
  //       "template_hlyx6hh", // Email.js template ID
  //       templateParams,
  //       "O99gbt5MW3qGYKNi3" // Email.js user ID
  //     )
  //     .then(
  //       (response) => {
  //         // console.log();
  //         toast.success("Email sent successfully:", response);
  //       },
  //       (error) => {
  //         toast.error("Email failed to send:", error);
  //       }
  //     );
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Send email using Email.js
      // sendEmail(); // Call the sendEmail function
      sendWhatsAppMessage();
      // Reset the form data
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } else {
      toast.error("Form validation failed. Please check your inputs.");
    }
  };

  const sendWhatsAppMessage = () => {
    const { name, email, message } = formData;

    // Create the WhatsApp message link
    const whatsappLink = `https://api.whatsapp.com/send?phone=923225273226&text=
    Contact Form Submission%0a%0a
    Name: ${name}%0a
    Email: ${email}%0a
    Message: ${message}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, "_blank");
  };

  const iconsList = [
    {
      iconURL: <Facebook />,
      link: "https://www.facebook.com/kardaanServices.co",
      alternativeText: "Facebook Logo",
    },
    {
      iconURL: <Instagram />,
      link: "https://www.instagram.com/kardaanservices/",
      alternativeText: "Instagram Logo",
    },
    {
      iconURL: <Linkedin />,
      link: "https://www.linkedin.com/company/kardaan/about/",
      alternativeText: "LinkedIn Logo",
    },
    {
      iconURL: <Whatsapp />,
      link: "https://api.whatsapp.com/send?phone=923225273226&text=Hello!%20Can%20I%20get%20more%20info%20about%20this%3F",
      alternativeText: "WhatsApp Logo",
    },
  ];
  return (
    <div
      className="contact-us flex flex-wrap w-full lg:flex lg:flex-row md:flex md:flex-col md:flex-wrap sm:flex sm:flex-col sm:flex-wrap "
      id="home-contact"
    >
      <ToastContainer />
      <FloatingWhatsApp
        accountName="Kardaan Services"
        phoneNumber="+923225273226"
        avatar={`${kardaanLogo}`}
        statusMessage="Typically replies within few minutes...!"
      />

      {/* Contact Form */}
      <div className="contact-form flex flex-col justify-between bg-[color:var(--mycolor)] 2xl:w-2/4 xl:w-2/4 lg:w-2/4 md:w-full sm:w-full w-full pt-[40px] pb-[55px] sm:pr-[90px] sm:pl-[50px] p-[30px] ">
        <div className="contact-heading">
          <h2 className="text-[#2A2A2A] sm:text-[40px] text-[30px] font-bold font-SFProDisplayFont leading-[2.8] break-words after:content-[''] after:block sm:after:w-[8%] after:w-[12%] after:h-[5px] after:bg-[color:var(--button-border-color)]">
            Contact Us
          </h2>
        </div>

        {/* Form */}
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="input-fields flex flex-col sm:flex-row justify-between mt-[40px]">
            {/* Name Input */}
            <div className="sm:w-[49%] w-full">
              <input
                className={`px-[18px] py-[13px] w-full  mb-4 sm:mb-0 border-none focus:outline-none ${
                  errors.name ? "border-red-500" : ""
                }`}
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <span className="text-red-500 block">{errors.name}</span>
              )}
            </div>
            {/* Email Input */}
            <div className="sm:w-[49%] w-full">
              <input
                className={`px-[18px] py-[13px] w-full  border-none focus:outline-none ${
                  errors.email ? "border-red-500" : ""
                }`}
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <span className="text-red-500 block">{errors.email}</span>
              )}
            </div>
          </div>

          {/* Message Textarea */}
          <textarea
            className={`w-full border-none pl-[18px] pt-2 mt-[20px] focus:outline-none ${
              errors.message ? "border-red-500" : ""
            }`}
            id="message"
            name="message"
            cols={40}
            rows={6}
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          {errors.message && (
            <span className="text-red-500 block">{errors.message}</span>
          )}

          {/* Submit Button */}
          <div className="message-btn flex self-end pt-[20px]">
            <button
              className="sm:px-[35px] sm:py-[4px] px-[35px] py-[8px] cursor-pointer bg-transparent border-[4px] border-white border-solid text-white hover:text-[var(--button-border-color)] sm:text-[20px] text-[16px] font-bold font-SFProDisplayFont tracking-wider break-words active:text-[color:var(--sub-heading-color)] active:border-[4px] hover:border-solid hover:border-[color:var(--button-border-color)]"
              type="submit"
              onClick={handleSubmit}
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
      </div>
      <div className="location-details w-full  2xl:w-2/4 xl:w-2/4 lg:w-2/4 md:w-full sm:w-full">
        <div className="location-header bg-[color:var(--button-border-color)]">
          <div className="social-links md:w-2/5 sm:w-2/5 lg:w-full sm:m-auto m-0 w-full h-[90px] flex lg:justify-normal justify-evenly sm:justify-between md:gap-3 items-center lg:pl-[65px] ">
            {iconsList.map((item, index) => (
              <a href={item.link} target="_blank" rel="noreferrer" key={index}>
                <div className="icon bg-white text-[color:var(--button-border-color)] rounded-full w-[44px] h-[44px] flex justify-center items-center text-[20px] hover:bg-[#ffb400] hover:text-white hover:transition-all hover:duration-500">
                  {item.iconURL}
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="office-location sm:pt-[70px] pt-[35px] w-[90%] sm:pl-[52px] pl-[15px]">
          <h2 className="text-[#2A2A2A] text-[16.75px] font-medium font-SFProDisplayFont capitalize leading-[21.64px] break-words">
            Our Offices
          </h2>
          <ul className="ml-[28px] list-disc font-SFProDisplayFont">
            <li className="text-[#2A2A2A] text-[16.75px] font-normal capitalize leading-[1.64] break-words">
              Islamabad : Office No.112, Grand XCITO, D-Markaz, Gulberg
              Islamabad.
            </li>
            <li className="text-[#2A2A2A] text-[16.75px] font-normal capitalize leading-[1.64] break-words">
              Abbottaad: Office No.14 KPK IT Park Mandian, Abbottabad.
            </li>
            <li className="text-[#2A2A2A] text-[16.75px] font-normal capitalize leading-[1.64] break-words">
              +92 322 5273226
            </li>
            <li className=" text-[16.75px] font-normal leading-[1.64] break-words ">
              <a
                href="mailto:contact@kardaanservices.com"
                className="text-[#2550ea] hover:text-[#FFB400]"
              >
                {" "}
                contact@kardaanservices.com
              </a>
            </li>
          </ul>
        </div>
        <div className="get-app w-[90%] sm:mr-auto sm:ml-[50px] mx-auto flex sm:flex-row flex-col justify-between items-end mt-10 mb-6 sm:gap-0 gap-6">
          <div className="left-section w-full sm:w-[50%] lg:w-[68%] flex flex-col justify-end ">
            <h2 className="text-black sm:text-left text-center text-[16.75px] font-medium font-SFProDisplayFont uppercase leading-[21.64px] break-words ml-1 sm:mb-0 mb-3">
              GET OUR APP
            </h2>
            <div className="app-link-images flex gap-3 md:w-3/5 lg:w-3/4 sm:w-3/4 mt-2 ml-1">
              <a
                href="https://apps.apple.com/us/app/kardaan-doorstep-services/id1444422720?ls=1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Apple}
                  alt=""
                  className="w-full object-cover hover:-translate-y-1 transition-all ease-in-out delay-180"
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=codematics.kardaan.ondemand.swiftservices"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Google}
                  alt=""
                  className="w-full object-cover hover:-translate-y-1 transition-all ease-in-out delay-180"
                />
              </a>
            </div>
          </div>
          <div className="powered-by flex flex-col sm:items-start items-right w-full sm:w-[30%]  md:w-[45%] justify-center items-center md:items-start lg:items-center">
            <p className="text-[rgba(0,0,0,0.381)] text-[16.75px] font-normal font-SFProDisplayFont leading-[21.64px] break-words">
              Powered by
            </p>
            <a
              href="https://www.codematics.co/en/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img className="w-full" src={Logo} alt="Codematics Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
