import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProjectItem from "../components/ProjectItem";
import ProjectImage1 from "../../src/assets/images/Elevator-Images/AQ4.jpg";
import ProjectThreeImage from "../../src/assets/images/Elevator-Images/PM1.jpg";
import ProjectTwoImage from "../../src/assets/images/Elevator-Images/IBC2.jpg";
import ProjectFourImage from "../assets/images/Construction/Supply-Road-Project/Thumbnail.jpg";
import ProjectFiveImage from "../assets/images/Construction/Batakundi-Naran-Hotel-Project/thumnail.jpg";
import ProjectSix from "../assets/images/Projects-Images/E1.jpg";
import ProjectSeven from "../assets/images/Projects-Images/Mac-Heights.jpg";
import ProjectEight from "../assets/images/Projects-Images/Farooq-Arcade.jpg";
import ProjectNine from "../assets/images/Projects-Images/Park-View.jpg";
import ProjectTen from "../assets/images/Projects-Images/Zam-Zam.jpg";
import ProjectEleven from "../assets/images/Projects-Images/Mumtaz-City.jpg";
import ProjectTweleve from "../assets/images/Projects-Images/Mehrban-Tower.jpg";
import ProjectThirteen from "../assets/images/Projects-Images/Plot-1708.jpg";
import ProjectFourteen from "../assets/images/Projects-Images/plot-47.jpg";
import ProjectFifteen from "../assets/images/Projects-Images/plot-112.jpg";
import ProjectSixteen from "../assets/images/Projects-Images/plot-98.jpg";
import ProjectSeventeen from "../assets/images/Projects-Images/AQ-Mall.jpg";

function ProjectsPage() {
  const projects = useMemo(
    () => [
      {
        id: 1,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "Abu Qasim",
        image: ProjectImage1,
        title: "Abul Qasim Super Market Bahria Town Karachi",
        description:
          "Discover elevated shopping at AQ Super Market, Bahria Town Karachi, with modern elevators by Kardaan Labour, ensuring convenience as you explore our diverse range effortlessly.",
      },
      {
        id: 2,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "Prime Medical",
        image: ProjectThreeImage,
        title: "Prime Medical College, Near 26 Num, Islamabad",
        description:
          "Elevators, expertly installed by Kardaan Labour's team, now grace Prime Medical College in Islamabad, enhancing accessibility and ensuring a smooth, efficient environment for all.",
      },
      {
        id: 3,
        projectCategory: "CONSTRUCTION_TAB",
        itemText: "construction",
        category: "Iqbal Road Site",
        image: ProjectFourImage,
        title: "Construction Site Abbottabad ( Iqbal Road Supply )",
        description:
          "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
      },
      {
        id: 4,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectTwoImage,
        title: "Infinity Business Center, Mumtaz City, Islamabad",
        description:
          "Experience elevated living in IBC Mumtaz City, Islamabad, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
      },
      {
        id: 5,
        projectCategory: "CONSTRUCTION_TAB",
        itemText: "construction",
        category: "Batakundi Naran Hotel Project",
        image: ProjectFiveImage,
        title: "Batakundi Naran Hotel Project",
        description:
          "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
      },
      {
        id: 6,
        projectCategory: "MAINTENANCE_TAB",
        itemText: "maintenance",
        category: "Iqbal Road Site",
        image: ProjectFourImage,
        title: "Construction Site Abbottabad ( Iqbal Road Supply )",
        description:
          "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
      },
      {
        id: 7,
        projectCategory: "RENOVATIONS_TAB",
        itemText: "renovations",
        category: "Batakundi Naran Hotel Project",
        image: ProjectFiveImage,
        title: "Batakundi Naran Hotel Project",
        description:
          "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
      },
      {
        id: 8,
        projectCategory: "MAINTENANCE_TAB",
        itemText: "maintenance",
        category: "Batakundi Naran Hotel Project",
        image: ProjectFiveImage,
        title: "Batakundi Naran Hotel Project",
        description:
          "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
      },
      {
        id: 9,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectSix,
        title: "Mac Heights by Mac builders",
        description:
          "Experience elevated living in Mac Heights, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
      },
      {
        id: 10,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectSeven,
        title: "Farooq Arcade 2 by Farooq Builders",
        description:
          "Experience refined living at Farooq Arcade, where Kardaan's elevators redefine vertical mobility with sophistication, enhancing daily convenience for residents with a touch of luxury.",
      },
      {
        id: 11,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectEight,
        title: "Park View City Head Office By Farooq Builders",
        description:
          "Experience elevated living in Park view City, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
      },
      {
        id: 12,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectNine,
        title: "Zam Zam Heights by Zam Zam Builders",
        description:
          "Discover elevated living at Zam Zam Heights, where Kardaan's elevators redefine vertical mobility with modern convenience, adding an extra layer of delight to residents' lives.",
      },
      {
        id: 13,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectTen,
        title: "West by Mumtaz City Owner Asim Butt Sahab",
        description:
          "Explore sophisticated living at Mumtaz City, Islamabad, where Kardaan's elevators revolutionize vertical mobility. Residents enjoy seamless modern convenience, enhancing their daily lives with unparalleled delight.",
      },
      {
        id: 14,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectEleven,
        title: "Meharban Tower by Rizwan Associates",
        description:
          "Embark on luxury living at Meharban Tower, where Kardaan's state-of-the-art elevators redefine vertical mobility. Residents relish the seamless fusion of modern convenience, adding an extra layer of delight to their daily lives.",
      },
      {
        id: 15,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectTweleve,
        title: "Farzan Heights, F-17 Markaz, Islamabad",
        description:
          "Immerse in elevated living at Farzan Heights, F-17 Markaz, Islamabad, where Kardaan's cutting-edge elevators redefine vertical mobility. Residents experience seamless convenience, enhancing daily delight.",
      },
      {
        id: 16,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectThirteen,
        title: "Plot 1708 DHA Sector F, Islamabad",
        description:
          "Indulge in elevated living at Plot 1708 DHA Sector F, Islamabad, where Kardaan's elevators redefine vertical mobility. Residents enjoy seamless convenience, enhancing modern living to the delight of all.",
      },
      {
        id: 17,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectFourteen,
        title: "Plot 47 by Skindar Property",
        description:
          "Embark on a refined living journey at Plot 47 by Skindar Property,Shaheen Chowk, where the exceptional elevators by Kardaan redefine vertical mobility. Residents relish in the seamless convenience, adding a modern touch to their everyday experience.",
      },
      {
        id: 18,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectFifteen,
        title: "Plot No 112 by Loyal Consultants,Phase 8 RawalPindi",
        description:
          "Experience a new level of luxury living at Plot No 112, Phase 8, RawalPindi. With Kardaan's elevators, residents enjoy effortless vertical mobility, elevating convenience to a modern standard that truly delights..",
      },
      {
        id: 19,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectSixteen,
        title: "Plot No 98 by Abu Al Qasim Builders",
        description:
          "Elevate your living experience at Plot No 98 by Abu Al Qasim Builders, where Kardaan's elevators provide seamless vertical mobility, enhancing modern convenience for residents' delight.",
      },
      {
        id: 20,
        projectCategory: "ELEVATOR_TAB",
        itemText: "elevator",
        category: "IBC City",
        image: ProjectSeventeen,
        title: "Abu Qasim Mall & Residency, Baharia Town Karachi",
        description:
          "Discover an enhanced living experience at Abu Qasim Mall,Karachi where Kardaan's elevators guarantee effortless vertical mobility, adding a touch of modern convenience that delights residents.",
      },
    ],
    []
  );
  // category descriptions
  const categoryDescriptions = {
    CONSTRUCTION:
      "Transforming visions into reality. We deliver precision, quality and innovation in every project.",
    ELEVATOR:
      "Top-Notch Installation and Maintenance Services for Both Imported and Semi-Imported Models.",
    MAINTENANCE:
      "Keeping Your Spaces in Top Shape with Reliable Maintenance Services.",
    RENNOVATIONS:
      "Let's Refresh Your Spaces Through Our Expert Renovation Services.",
  };

  const location = useLocation();
  const { category } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const filteredProjects = projects
  //   .filter((project) => {
  //     console.log(project.itemText !== category);
  //     if (category === "ALL") {
  //       return true;
  //     } else {
  //       return project.itemText === category;
  //     }
  //   })
  //   .sort(() => Math.random() - 0.5);
  const [shuffledProjects, setShuffledProjects] = useState([]);
  useEffect(() => {
    // Shuffle the projects array and set it to the state
    const shuffled = [...projects].sort(() => Math.random() - 0.5);
    setShuffledProjects(shuffled);
  }, [projects]); // Empty dependency array ensures this effect runs only once on mount

  //Filter projects based on the selected category
  //Order Will be random on each visit, whenever category === "ALL"
  const filteredProjects = shuffledProjects.filter((project, index) => {
    // It will map only on five projects/items
    if (category === "all") {
      return true;
    } else {
      return project.itemText === category;
    }
  });
  // console.log(filteredProjects);

  // Get the description for the current category
  const categoryDescription =
    categoryDescriptions[category] ||
    "Kardaan epitomizes a commitment to excellence in construction, renovation, maintenance, material supply, elevator and lift installation, and solar installation services.";
  return (
    <div>
      <div className="main-construction flex w-full">
        <div className="construction bg-projectsBackgroundImage w-full bg-cover bg-center bg-no-repeat z-0 relative sm:h-[541px] h-[390px] flex flex-col justify-center items-center before:content-[''] before:absolute before:top-0 before:right-0 before:w-full before:h-full before:bg-[#000000b5] before:z-[1]">
          <div className="inner-content sm:w-3/5 w-4/5 absolute">
            <h1 className=" text-white sm:text-5xl text-[30px] font-SFProDisplayFont font-bold capitalize break-words leading-[40px] pl-8 relative z-[999!important] before:w-[12px] before:bg-[var(--mycolor)] before:absolute before:top-0 before:left-0 before:h-full">
              {category.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}{" "}
              Projects
            </h1>

            <p className="sm:text-[22px] text-[18px] leading-[1.2] break-words relative w-[90%] mt-[15px] font-SFProDisplay font-normal text-white z-[999!important]">
              {categoryDescription}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white py-8">
        <div className="projects-main w-[85%] mx-auto">
          <h2 className="text-center m-auto sm:text-[40px] text-[25px] font-bold after:content-[''] sm:after:w-[8%] after:w-[10%] after:m-auto after:mt-2 after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
            {category.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}{" "}
            Projects
          </h2>
          {/* <ProjectItem projects={projects} showButton={false} columnGap="gap-5" /> */}
          <ProjectItem
            projects={filteredProjects}
            showButton={false}
            columnGap="gap-5"
            category={category}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
