import React, { useRef, useState } from "react";
import { X, ChevronLeft, ChevronRight } from "lucide-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const PopupSlider = ({ onClose, projects }) => {
  const [activeStep, setActiveStep] = useState(0);
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) =>
      prevStep === projects.length - 1 ? 0 : prevStep + 1
    );
  };

  const handlePrev = () => {
    setActiveStep((prevStep) =>
      prevStep === 0 ? projects.length - 1 : prevStep - 1
    );
  };

  // useEffect(() => {
  //     const intervalId = setInterval(handleNext, 2000);

  //     return () => {
  //         clearInterval(intervalId);
  //     };
  // }, []);
  // Automatic Sliding the Images
  //   useEffect(() => {
  //     const checkDeviceWidth = () => {
  //       return window.innerWidth < 640;
  //     };

  //     if (checkDeviceWidth()) {
  //       const intervalId = setInterval(handleNext, 2000);

  //       return () => {
  //         clearInterval(intervalId);
  //       };
  //     }
  //   }, [handleNext]);

  const isVideoSlide = activeStep === 0 && projects[0].type === "video";

  return (
    <div
      ref={modalRef}
      onClick={closeModal}
      onTouchStart={handleNext}
      onTouchEnd={handlePrev}
      className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center z-[1000] h-full w-full"
    >
      <div className="flex flex-col items-center mx-4 sm:w-[75%!important] w-[85%!important] h-[90vh!important] m-[auto!important]">
        <Box
          sx={{ flexGrow: 1, height: 120 }}
          className="flex flex-col justify-center h-full w-full relative"
        >
          <div className="sm:h-[466px] md:h-[490px] xl:h-[530px] h-[280px] relative">
            <button
              className="absolute top-2 sm:right-2 right-1 bg-[#FFFFFF] rounded-full p-1 text-black active:bg-[#2a2a2a] active:text-white z-[1111]"
              onClick={onClose}
            >
              <X size={28} />
            </button>
            {isVideoSlide ? (
              <iframe
                width="100%"
                height="100%"
                src={projects[0].videoUrl}
                title={projects[0].title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : (
              // Render image for subsequent slides
              <Box
                component="img"
                sx={{
                  display: "block",
                  height: "100%",
                  objectFit: "fill",
                  width: "100%",
                }}
                src={projects[activeStep].image}
                alt={projects[activeStep].title}
              />
            )}
          </div>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 25,
              fontFamily: "SF Pro Display",
              alignItems: "center",
              height: 70,
              p: 2,
              bgcolor: "#000000",
              color: "white",
            }}
          >
            <Typography
              fontWeight={"bold"}
              // fontSize={"18px"}
              textAlign={"center"}
              className="sm:text-[18px] text-base"
            >
              {projects[activeStep].title}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"medium"}
              color={"#2a2a2a"}
              textAlign={"center"}
              className="sm:block hidden"
            >
              {projects[activeStep].description}
            </Typography>
          </Paper>

          <div className="sm:block block">
            <button
              className="bg-[#FFB400] active:bg-[#2a2a2a] rounded-full p-2 absolute flex items-center space-x-4 top-1/2 sm:left-[-50px] left-0 transform -translate-x-1/2 -translate-y-1/2"
              onClick={handlePrev}
            >
              <ChevronLeft
                size={30}
                className="text-[#2a2a2a] active:text-white"
              />
            </button>
            <button
              className="bg-[#FFB400] active:bg-[#2a2a2a] rounded-full p-2 absolute flex items-center space-x-4 top-1/2 sm:right-[-100px] right-[-45px] transform -translate-x-1/2 -translate-y-1/2"
              onClick={handleNext}
            >
              <ChevronRight
                size={30}
                className="text-[#2a2a2a] active:text-white"
              />
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default PopupSlider;
