import React from "react";
import InstallationImage from "../../assets/images/Elevator-Images/ElevatorInstallation.png";
import DesignImage from "../../assets/images/Elevator-Images/ElevatorDesign.png";
import InpsectionImage from "../../assets/images/Elevator-Images/ElevatorInspection.png";
import MaintenanceImage from "../../assets/images/Elevator-Images/MaintenanceProgram.png";
import UpgradingImage from "../../assets/images/Elevator-Images/RepairUpgrade.png";
import ModernServiceImage from "../../assets/images/Elevator-Images/ModernServices.png";
import ServiceItem from "../ServiceItem";

// const EscalatorServiceItem = ({ imgUrl, serviceTitle, serviceDetails }) => (
//   <div className="flex ">
//     <div className="w-2/5">
//       <img className="h-full object-cover" src={imgUrl} alt="" />
//     </div>
//     <div className="service-text w-3/5 pl-[40px] pr-[27px] pt-[40px] bg-white">
//       <h2 className="text-black text-[27.82px] font-SFProDisplayFont font-medium leading-[1.2] break-words ">
//         {serviceTitle}
//       </h2>
//       <p>{serviceDetails}</p>
//     </div>
//   </div>
// );

const ElevatorServices = () => {
  const servicesList = [
    {
      imgUrl: InstallationImage,
      serviceTitle: "Elevator & Escalator Installation",
      serviceDetails:
        "Comprehensive installation services for both imported and semi-imported elevators and escalators, covering design, construction, and final commissioning.",
    },
    {
      imgUrl: DesignImage,
      serviceTitle: "Custom Elevator and Escalator Design",
      serviceDetails:
        "Collaborate closely with clients to create tailor-made elevator and excavator solutions that align with their specific requirements, aesthetics, and building specifications.",
    },
    {
      imgUrl: InpsectionImage,
      serviceTitle: "Safety Compliance and Inspection:",
      serviceDetails:
        "Ensure that all elevators are installed in strict adherence to safety codes and regulations, conducting thorough safety inspections and testing.",
    },
    {
      imgUrl: MaintenanceImage,
      serviceTitle: "Preventive Maintenance Program",
      serviceDetails:
        "Offer routine preventive maintenance programs to maintain elevators and escalators in optimal condition, reducing downtime and minimizing costly repairs.",
    },
    {
      imgUrl: UpgradingImage,
      serviceTitle: "Repair and Upgrade Services",
      serviceDetails:
        "Kitchen Equipment: Appliances and fixtures for kitchens.",
    },
    {
      imgUrl: ModernServiceImage,
      serviceTitle: "Modernization Services",
      serviceDetails:
        "Modernize older elevator and escalator systems to meet contemporary safety and technology standards, ultimately extending their operational lifespan.",
    },
  ];

  return (
    <div className="pt-[35px] pb-[50px] px-[20px] bg-[color:#EFEFEF]">
      <div>
        <h2 className="text-center text-[color:black] text-[36px] font-SFProDisplayFont font-bold leading-loose after:content-[''] sm:after:w-[8%] after:w-[12%] after:m-auto after:h-[5px] after:bg-[#FFB400] after:block">
          Our Services
        </h2>
      </div>
      <div className="flex flex-wrap justify-center gap-6 mt-[1.5rem]">
        {servicesList.map((item) => (
          <div key={item.serviceTitle}>
            <ServiceItem
              className="max-w-lg md:max-w-md lg:max-w-md"
              imgUrl={item.imgUrl}
              heading={item.serviceTitle}
              content={item.serviceDetails}
              headingSize="text-[18.96px] leading-[1.3]"
              contentSize="text-[13.54px] leading-[16.54px] tracking-wide"
            ></ServiceItem>
          </div>
        ))}
      </div>
      {/* <div className="view-all-btn flex items-center justify-center mt-[50px] w-full">
        <button className="border-[4px] border-solid border-[color:var(--button-border-color)] text-[color:var(--button-border-color)] cursor-pointer px-[50px] py-[12px] bg-transparent text-[20px] font-bold tracking-[0.60px] break-words active:bg-[color:var(--button-border-color)] active:text-[color:white]">
          VIEW ALL
        </button>
      </div> */}
    </div>
  );
};

export default ElevatorServices;
