import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/HomeComponents/HeroSection";
import Services from "../components/HomeComponents/Services";
import Quote from "../components/HomeComponents/Quote";
import About from "../components/HomeComponents/About";
import LatestProjects from "../components/HomeComponents/LatestProjects";
import ProjectShowCase from "../components/HomeComponents/ProjectShowCase";
import Testimonials from "../components/HomeComponents/Testimonials";
import Team from "../components/HomeComponents/Team";
import Articles from "../components/HomeComponents/Articles";

function HomePage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <div
        id="home"
        className="flex flex-col  min-h-[491px] 2x:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col"
      >
        <div className="lg:w-[65%] sm:w-full">
          <HeroSection />
        </div>
        <div className="lg:w-[35%] sm:w-full">
          <Services />
        </div>
      </div>
      <Quote />
      <About />
      <div className="w-full mt-8">
        <LatestProjects />
      </div>
      <ProjectShowCase />
      <Testimonials />
      <Team />
      <Articles />
    </div>
  );
}

export default HomePage;
