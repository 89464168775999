import React, { useId } from "react"

function Input({ label, type = "text", className = "", ...props }, ref) {
  const id = useId()
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={id} className="font-RubikFont text-black inline-block mb-1">
          {label}
        </label>
      )}
      <input
        className={`border border-black bg-white text-black outline-none font-RubikFont w-full px-6 py-2 ${className}`}
        id={id}
        type={type}
        {...props}
        ref={ref}
      />
    </div>
  )
}

export default React.forwardRef(Input)
