import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ElevatorImage from "../assets/images/Elevator-Images/image20.svg";
import ElevatorServices from "../components/ElevatorComponents/ElevatorServices";
import OurTeam from "../components/HomeComponents/Team";
import ExperienceOverview from "../components/ElevatorComponents/ExperienceOverview";
import Clients from "../components/HomeComponents/Clients";
// import UserTestimonials from "../components/ElevatorComponents/UserTestimonials"
import Button from "../components/Button";
import ProjectItem from "../components/ProjectItem";

import ProjectOneImage from "../assets/images/Elevator-Images/AQ4.jpg";
import ProjectThreeImage from "../assets/images/Elevator-Images/PM1.jpg";
import ProjectTwoImage from "../assets/images/Elevator-Images/IBC2.jpg";
import UserReviews from "../components/MaintenanceComponents/UserReviews";
import { Link } from "react-router-dom";
const users = [
  {
    description:
      "“We are thrilled with the elevator service provided by Kardaan at our hospital. The installation was seamless, and the elevators have been operating smoothly ever since. Our patients, visitors, and staff appreciate the reliability and efficiency of the service. Thank you for your excellent work!”",
    name: "Dr Wajid ( MD Prime Medical College)",
  },
  {
    description:
      "“I am satisfied with the lift installation work done by kardaan team in my new project. The team is professional and very responsive. The equipment installed is of good quality. I have also given maintenance services to kardaan of 2 other projects as well. Highly recommended company for lifts services.”",
    name: "Mr. Ali ( Mumtaz City )",
  },
  {
    description:
      "“Kardaan team installed 2 elevators in our newly constructed building. They installed top-of-the line equipment and told us everything in detail. Satisfactory work done, completed on time and the team is always available. It’s been six months now and everything is running smoothly.”",
    name: "Mr Majid ( Gulf Alliance )",
  },
];
const ElevatorPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const projects = [
    {
      id: 1,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      image: ProjectOneImage,
      title: "Abul Qasim Super Market Bahria Town Karachi",
      description:
        "Discover elevated shopping at AQ Super Market, Bahria Town Karachi, with modern elevators by Kardaan Labour, ensuring convenience as you explore our diverse range effortlessly.",
      category: "Abu Qasim",
    },
    {
      id: 2,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      image: ProjectThreeImage,
      title: "Prime Medical College, Near 26 Num, Islamabad",
      description:
        "Elevators, expertly installed by Kardaan Labour's team, now grace Prime Medical College in Islamabad, enhancing accessibility and ensuring a smooth, efficient environment for all.",
      category: "Prime Medical",
    },
    {
      id: 3,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      image: ProjectTwoImage,
      title: "Infinity Business Center, Mumtaz City, Islamabad",
      description:
        "Experience elevated living in IBC Mumtaz City, Islamabad, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
      category: "IBC City",
    },
  ];
  return (
    <div>
      {/* hero */}
      <div className="w-full bg-white flex items-center justify-center min-h-[524px] lg:h-[490px] border-t">
        <div className="w-[100%] md:w-3/6 flex flex-col items-start pl-10 text-left md:items-start md:text-left md:pl-24">
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Fast, Reliable
          </span>
          <span className="leading-[1.0] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Elevator Repairs!
          </span>
          <p className="text-[#282828] opacity-[0.50] text-xl md:text-[19px] lg:text-[24px] font-normal leading-snug mt-[27px] break-words font-SFProDisplayFont w-full text-left md:text-left">
            Elevating Excellence in Vertical Transportation. We specialize in
            the design, installation, and maintenance of custom elevators and
            excavators, ensuring safety compliance and modernization to meet the
            highest industry standards. Elevate your expectations with Kardaan
            elevator services.
          </p>
          <div className="mt-[27px]">
            <Link to="/quote-form">
              <Button
                bgColor="bg-[#282828]"
                textColor="text-white"
                className="tracking-wider border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
              >
                {" "}
                GET OUR SERVICE
              </Button>
            </Link>
          </div>
        </div>
        <div className="hidden md:block md:w-3/6 h-[490px] ">
          <img className="h-full" src={ElevatorImage} alt="" />
        </div>
      </div>
      {/* hero */}
      <ElevatorServices />
      <ExperienceOverview />
      <div className="bg-[white] pt-12 pb-[1px]">
        <div className="projects-main w-[85%] mx-auto">
          <h3 className="text-center m-auto sm:text-[40px] text-[30px] font-bold after:content-[''] sm:after:w-[6%] after:w-[12%] after:m-auto after:mt-[20px] after:h-[5px] after:bg-[color:var(--mycolor)] after:block mb-8">
            Latest Projects
          </h3>
          <ProjectItem projects={projects} showButton />
        </div>
      </div>
      <OurTeam />
      <UserReviews
        reviews={users}
        heading="What our Clients are saying?"
        bgColor={false}
      />
      {/* <UserTestimonials /> */}
      <div className="sm:px-10 p-0">
        <Clients />
      </div>
      {/* <div className="w-full bg-[color:#F7F7F7] pt-[20px]">
        {" "}
        <h2 className="text-center  text-[45px] font-bold text-[color:#2a2a2a] sm:after:w-[6%] after:w-0 after:m-auto after:mt-[10px] after:mb-[20px] after:h-[5px] after:bg-[#FFB400] after:block">
          Our Clients
        </h2>
        <img src={Clients} alt="Clients Logo Pic" className="w-full" />
      </div> */}
    </div>
  );
};

export default ElevatorPage;
