/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Input from "./Input";
import Select from "./Select";
import { useForm } from "react-hook-form";
import MultiSelect from "./MultiSelect";
import Button from "./Button";
import Navbar from "./HomeComponents/Navbar";
import Footer from "./Footer/Footer";
// import emailjs from "emailjs-com";

function Quote() {
  const location = useLocation();
  const [initialInputValue, setInitialInputValue] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    singleSelect: "",
    multiselect: "",
    phoneNumber: "",
    date: "",
    area: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [key, setKey] = useState(0);

  const submitForm = (data) => {
    // console.log(data);
    const selectedServices = data.multiselect
      ? data.multiselect.map((option) => option.label).join(", ")
      : "";
    // console.log(selectedServices);
    const url = `https://api.whatsapp.com/send?phone=923225273226&text=
    Name: ${data.name}%0A
    Email: ${data.email}%0A
    Category: ${data.singleSelect.value}%0A
    Services: ${selectedServices}%0A
    Phone Number: ${data.phoneNumber}%0A
    Date: ${data.date}%0A
    Additional Details: ${data.area}
  `;

    window.open(url, "_blank").focus();

    setFormData({
      name: "",
      email: "",
      singleSelect: "",
      multiselect: "",
      phoneNumber: "",
      date: "",
      area: "",
    });
    // Increment the key to trigger remount
    setKey((prevKey) => prevKey + 1);
    reset({
      name: "",
      email: "",
      phoneNumber: "",
      date: "",
      area: "",
    });
  };

  // const submitForm = async (data) => {
  //   try {
  //     // ... existing code ...

  //     // Send data to WhatsApp
  //     const selectedServices = data.multiselect
  //       ? data.multiselect.map((option) => option.label).join(", ")
  //       : "";
  //     const whatsappMessage = `
  //       Name: ${data.name}
  //       Email: ${data.email}
  //       Category: ${data.singleSelect.value}
  //       Services: ${selectedServices}
  //       Phone Number: ${data.phoneNumber}
  //       Date: ${data.date}
  //       Additional Details: ${data.area}
  //     `;
  //     const whatsappUrl = `https://api.whatsapp.com/send?phone=923225273226&text=${encodeURIComponent(
  //       whatsappMessage
  //     )}`;

  //     // Ask user for confirmation
  //     const shouldSendMessage = window.confirm(
  //       "Your data has been submitted. Do you want to send a WhatsApp message?"
  //     );
  //     //If user wants to send message this if will be executed
  //     if (shouldSendMessage) {
  //       window.open(whatsappUrl, "_blank").focus();
  //     }

  //     // Send data to Email.js
  //     const emailTemplateParams = {
  //       to_email: "admin@kardaanservices.com",
  //       from_name: data.name,
  //       from_email: data.email,
  //       subject: "Kardaan Services",
  //       message: `
  //       Name: ${data.name}
  //       Email: ${data.email}
  //       Category: ${data.singleSelect.value}
  //       Services: ${selectedServices}
  //       Phone Number: ${data.phoneNumber}
  //       Date: ${data.date}
  //       Additional Details: ${data.area}
  //       `,
  //     };

  //     await emailjs.send(
  //       "service_q5az13w", // Email.js service ID
  //       "template_hlyx6hh", // Email.js template ID
  //       emailTemplateParams, //Email Template/ View
  //       "O99gbt5MW3qGYKNi3" // Email.js user ID
  //     );

  //     // Reset the form
  //     reset({
  //       name: "",
  //       email: "",
  //       singleSelect: null,
  //       multiselect: null,
  //       phoneNumber: "",
  //       date: "",
  //       area: "",
  //     });
  //   } catch (error) {
  //     console.error("Error sending data:", error);
  //   }
  // };
  let categoryServices = [
    {
      value: "New Home Construction",
      label: "New Home Construction",
      category: "Construction Services",
    },
    {
      value: "Commercial Construction",
      label: "Commercial Construction",
      category: "Construction Services",
    },
    {
      value: "Custom Home Construction",
      label: "Custom Home Construction",
      category: "Construction Services",
    },
    {
      value: "Residential Renovations",
      label: "Residential Renovations",
      category: "Construction Services",
    },
    {
      value: "Commercial Fit-Outs",
      label: "Commercial Fit-Outs",
      category: "Construction Services",
    },
    {
      value: "Additions and Extensions",
      label: "Additions and Extensions",
      category: "Construction Services",
    },
    {
      value: "Interior Build-Outs",
      label: "Interior Build-Outs",
      category: "Construction Services",
    },
    {
      value: "Foundation and Structural Work",
      label: "Foundation and Structural Work",
      category: "Construction Services",
    },
    {
      value: "Concrete Work",
      label: "Concrete Work",
      category: "Construction Services",
    },
    {
      value: "LandScaping & Outdoor Construction",
      label: "LandScaping & Outdoor Construction",
      category: "Construction Services",
    },
    {
      value: "Energy-Efficient Building",
      label: "Energy-Efficient Building",
      category: "Construction Services",
    },
    {
      value: "Project Management & Consultation",
      label: "Project Management & Consultation",
      category: "Construction Services",
    },
    {
      value: "Demolition Services",
      label: "Demolition Services",
      category: "Construction Services",
    },
    {
      value: "Elevator & Escalator Installation",
      label: "Elevator & Escalator Installation",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Custom Elevator & Escalator Design",
      label: "Custom Elevator & Escalator Design",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Safety Compliance and Inpsection",
      label: "Safety Compliance and Inpsection",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Preventive Maintenance Program",
      label: "Preventive Maintenance Program",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Repair & Upgrade Services",
      label: "Repair & Upgrade Services",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Modernization Services",
      label: "Modernization Services",
      category: "Elevator / Escalator Services",
    },
    {
      value: "Electrician",
      label: "Electrician",
      category: "Maintenance Services",
    },
    {
      value: "Plumber",
      label: "Plumber",
      category: "Maintenance Services",
    },
    {
      value: "Carpenter",
      label: "Carpenter",
      category: "Maintenance Services",
    },
    {
      value: "AC/Technician",
      label: "AC/Technician",
      category: "Maintenance Services",
    },
    {
      value: "Gardener",
      label: "Gardener",
      category: "Maintenance Services",
    },
    {
      value: "Painter",
      label: "Painter",
      category: "Maintenance Services",
    },
    {
      value: "Interior Remodeling",
      label: "Interior Remodeling",
      category: "Renovation Services",
    },
    {
      value: "Exterior Remodeling",
      label: "Exterior Remodeling",
      category: "Renovation Services",
    },
    {
      value: "Whole House Renovation",
      label: "Whole House Renovation",
      category: "Renovation Services",
    },
    {
      value: "Additions",
      label: "Additions",
      category: "Renovation Services",
    },
    {
      value: "Custom Carpentry",
      label: "Custom Carpentry",
      category: "Renovation Services",
    },
    {
      value: "Flooring Services",
      label: "Flooring Services",
      category: "Renovation Services",
    },
    {
      value: "Painting & Wall Covering",
      label: "Painting & Wall Covering",
      category: "Renovation Services",
    },
    {
      value: "Electrical & Plumbing",
      label: "Electrical & Plumbing",
      category: "Renovation Services",
    },
    {
      value: "Energy Efficiency Upgrades",
      label: "Energy Efficiency Upgrades",
      category: "Renovation Services",
    },
    {
      value: "Structural Repairs",
      label: "Structural Repairs",
      category: "Renovation Services",
    },
    {
      value: "Commercial Renovations",
      label: "Commercial Renovations",
      category: "Renovation Services",
    },
    {
      value: "Kitchen Upgrades",
      label: "Kitchen Upgrades",
      category: "Renovation Services",
    },
    {
      value: "Bathroom Makeovers",
      label: "Bathroom Makeovers",
      category: "Renovation Services",
    },
    {
      value: "Interior",
      label: "Interior",
      category: "Renovation Services",
    },
    {
      value: "Steel (Rebar)",
      label: "Steel (Rebar)",
      category: "Construction Material Supply",
    },
    {
      value: "Cement",
      label: "Cement",
      category: "Construction Material Supply",
    },
    {
      value: "Sand",
      label: "Sand",
      category: "Construction Material Supply",
    },
    {
      value: "Gravel (Crushed Stone)",
      label: "Gravel (Crushed Stone)",
      category: "Construction Material Supply",
    },
    {
      value: "Electric Cables",
      label: "Electric Cables",
      category: "Construction Material Supply",
    },
    {
      value: "Electric Conducts",
      label: "Electric Conducts",
      category: "Construction Material Supply",
    },
    {
      value: "Plumbing Sewerage Lines (PVC Pipes)",
      label: "Plumbing Sewerage Lines (PVC Pipes)",
      category: "Construction Material Supply",
    },
    {
      value: "Plumbing Gas & Water Lines (PPR Pipes)",
      label: "Plumbing Gas & Water Lines (PPR Pipes)",
      category: "Construction Material Supply",
    },
    {
      value: "Bathroom Accessories & CP Sets",
      label: "Bathroom Accessories & CP Sets",
      category: "Construction Material Supply",
    },
    {
      value: "Kitchen Equipments",
      label: "Kitchen Equipments",
      category: "Construction Material Supply",
    },
    {
      value: "House Furniture",
      label: "House Furniture",
      category: "Construction Material Supply",
    },
    {
      value: "Plywood Sheets",
      label: "Plywood Sheets",
      category: "Construction Material Supply",
    },
    {
      value: "Wooden Doors",
      label: "Wooden Doors",
      category: "Construction Material Supply",
    },
    {
      value: "Fiber Doors",
      label: "Fiber Doors",
      category: "Construction Material Supply",
    },
    {
      value: "Tiles",
      label: "Tiles",
      category: "Construction Material Supply",
    },
    {
      value: "Marble and Granite",
      label: "Marble and Granite",
      category: "Construction Material Supply",
    },
    {
      value: "Consultation",
      label: "Consultation",
      category: "Solar Panel Services",
    },
    {
      value: "Assessment",
      label: "Assessment",
      category: "Solar Panel Services",
    },
    {
      value: "Installation",
      label: "Installation",
      category: "Solar Panel Services",
    },
    {
      value: "Support",
      label: "Support",
      category: "Solar Panel Services",
    },
  ];

  const handleCategoryChange = (newCategory) => {
    setInitialInputValue(newCategory);
  };
  // useEffect(() => {
  //   if(!initialInputValue){
  //     setServiceItems(categoryServices)
  //   }
  // else
  // {
  // const filterData = categoryServices.filter((option) =>
  //  option.category ===      initialInputValue)
  //     setServiceItems(filterData)
  // }
  // }, [initialInputValue, setInitialInputValue, setServiceItems])
  useEffect(() => {
    const filterData = categoryServices.filter(
      (option) => option.category === initialInputValue
    );
    setServiceItems(filterData);
  }, [initialInputValue, setInitialInputValue, setServiceItems]);

  return (
    <>
      <Navbar />
      <div className="w-full" key={key}>
        <div className="max-w-[1005px] mx-auto lg:pt-14 sm:pt-5">
          <div className="bg-[#EEEFF1] max-w-[1005px] text-center sm:px-20 px-10 py-8 flex flex-col justify-center">
            <h1 className="text-[#282828] font-SFProDisplayFont sm:text-[45px] text-[35px] leading-[49px] font-medium ">
              Quote Request
            </h1>
            <p className="font-SFProDisplayFont sm:text-[26px] text-[20px] leading-[33px] font-normal mt-4">
              Please fill out the form below to request a quote. we will back to
              you within 24 hours of receiving your request. Thank you for your
              patience.
            </p>
          </div>
          <form
            className="w-full my-8 lg:p-0 sm:p-5 p-5"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="flex gap-6 mt-6 sm:flex-row flex-col">
              <div className="sm:w-6/12 w-full">
                <Input
                  className="placeholder:text-[#2a2a2a]"
                  label={
                    <>
                      <span>Full Name</span>
                      <span className="text-red-700">*</span>
                    </>
                  }
                  placeholder="Enter full name"
                  {...register("name", {
                    required: true,
                  })}
                ></Input>
                {errors.name?.type === "required" && (
                  <p className="text-red-700">Name is required</p>
                )}
              </div>
              <div className="sm:w-6/12 w-full">
                <Input
                  className="placeholder:text-[#2a2a2a]"
                  type="email"
                  label={
                    <>
                      <span>Email Address</span>
                      <span className="text-red-700">*</span>
                    </>
                  }
                  placeholder="Enter email address"
                  {...register("email", {
                    required: true,
                  })}
                ></Input>
                {errors.email?.type === "required" && (
                  <p className="text-red-700">Email is required</p>
                )}
              </div>
            </div>
            <div className="flex gap-6 mt-6 sm:flex-row flex-col">
              <div className="sm:w-6/12 w-full">
                <Select
                  label="Category"
                  className="px-4 sm:w-6/12 w-full placeholder:text-[#2a2a2a]"
                  placeholder="Select category"
                  options={[
                    {
                      value: "Construction Services",
                      label: "Construction Services",
                    },
                    {
                      value: "Elevator / Escalator Services",
                      label: "Elevator / Escalator Services",
                    },
                    {
                      value: "Renovation Services",
                      label: "Renovation Services",
                    },
                    {
                      value: "Maintenance Services",
                      label: "Maintenance Services",
                    },
                    {
                      value: "Construction Material Supply",
                      label: "Construction Material Supply",
                    },
                    {
                      value: "Solar Panel Services",
                      label: "Solar Panel Services",
                    },
                  ]}
                  control={control}
                  name="singleSelect"
                  onChangeHandler={handleCategoryChange}
                />
                {/* {errors.singleSelect?.type === "required" && (
                  <p className="text-red-700">Field is required</p>
                )} */}
              </div>

              <div className="sm:w-6/12 w-full ">
                <MultiSelect
                  className="placeholder:text-[#2a2a2a]"
                  options={serviceItems}
                  label="Services"
                  control={control}
                  name="multiselect"
                ></MultiSelect>
                {/* {errors.multiselect?.type === "required" && (
                  <p className="text-red-700">Field is required</p>
                )} */}
              </div>
            </div>
            <div className="flex gap-6 mt-6 sm:flex-row flex-col">
              {/* <Input
                label="Phone Number"
                type="number"
                placeholder="Enter your phone number"
                {...register("phoneNumber", {
                  required: true,
                })}
              ></Input> */}
              <div className="sm:w-6/12 w-full">
                <Input
                  className="placeholder:text-[#2a2a2a]"
                  label={
                    <>
                      <span>Phone Number</span>
                      <span className="text-red-700">*</span>
                    </>
                  }
                  id="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  {...register("phoneNumber", {
                    required: true,
                    onChange: (element) => {
                      setValue(
                        "phoneNumber",
                        element.target.value.replace(/[^0-9]/g, "")
                      );
                    },
                    validate: {
                      matchPattern: (value) =>
                        /^(\+92|0)(3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])?\d{8}$/.test(
                          value
                        ) || "Not a valid phone number",
                    },
                  })}
                />
                {errors.phoneNumber?.type === "required" && (
                  <p className="text-red-700">Phone is required</p>
                )}
                {errors.phoneNumber?.type === "matchPattern" && (
                  <p className="text-red-700">Not a valid phone number</p>
                )}
              </div>
              <div className="sm:w-6/12 w-full">
                <Input
                  label={
                    <>
                      <span>Preferred Date</span>
                      <span className="text-red-700">*</span>
                    </>
                  }
                  type="date"
                  name="date"
                  {...register("date", {
                    required: true,
                  })}
                  className="text-[#2a2a2a!important] placeholder:text-[#2a2a2a]"
                ></Input>
                {errors.date?.type === "required" && (
                  <p className="text-red-700">Date is required</p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <label className="font-RubikFont text-black inline-block mb-1">
                Additional Details
                <span className="text-red-700">*</span>
              </label>
              <textarea
                placeholder="Additional Details"
                className={`border border-black bg-white text-black outline-none font-RubikFont w-full h-[117px] px-6 py-2 placeholder:text-[#2a2a2a]`}
                {...register("area")}
              ></textarea>
              {errors.date?.type === "required" && (
                <p className="text-red-700">Additional detalis required</p>
              )}
            </div>
            <div className="className mt-6">
              <Button
                className="uppercase w-full  font-SFProDisplayFont text-base font-semibold"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Quote;
