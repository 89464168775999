import React, { useState, useEffect } from "react";
import WorkingImage from "../../assets/images/KardaanWorking-Images/2.b62caa2e.webp";
import ImageTwo from "../../assets/images/KardaanWorking-Images/3.733b0ad0.webp";
import LocationImage from "../../assets/images/KardaanWorking-Images/1.dbe20b13.webp";
import OrderImage from "../../assets/images/KardaanWorking-Images/4.8f233869.webp";
import ExperienceImage from "../../assets/images/KardaanWorking-Images/5.b63fa389.webp";

const KardaanWorking = () => {
  const slides = [
    { url: LocationImage },
    { url: WorkingImage },
    { url: ImageTwo },
    { url: OrderImage },
    { url: ExperienceImage },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      setCurrentIndex(nextIndex);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, slides.length]);

  return (
    <>
      <div
        id="kardaan-working"
        className="flex flex-col justify-center items-center p-4 md:p-[40px] pb-6 md:pb-[40px]"
      >
        <h2 className="text-[color:#2A2A2A] text-[24px] md:text-[36px] font-SFProDisplayFont font-bold leading-[2.2] break-words pb-8 md:pb-[22px] after:content-[''] sm:after:w-[12%] after:w-[15%] after:m-auto  after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
          How Kardaan Works?
        </h2>
        <div className="w-full relative group">
          <img
            src={slides[currentIndex].url}
            alt={`Slide ${currentIndex + 1}`}
            className="object-cover h-auto mx-auto duration-600 rounded-3xl"
          />

          <div className="flex justify-between m-auto w-[26%] lg:w-[10%] md:w-[12%] sm:w-[15%] top-4 pt-6">
            {slides.map((_, index) => (
              <div
                key={index}
                onClick={() => goToSlide(index)}
                className="text-3xl cursor-pointer flex "
              >
                <button
                  type="button"
                  className={`w-[14px] h-[14px] rounded-full ${
                    currentIndex === index ? "bg-[#666666]" : "bg-[#D9D9D9]"
                  }`}
                  aria-current="false"
                  aria-label={`Slide ${index + 1}`}
                  data-carousel-slide-to={index}
                ></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default KardaanWorking;
