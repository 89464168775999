import React, { useEffect, useState } from "react"
import ReactSelect, { components } from "react-select"
import { Controller } from "react-hook-form"

const Option = (props) => (
  <components.Option {...props}>
    <label style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <input type="checkbox" />
      {props.label}
    </label>
  </components.Option>
)

function MultiSelect({ options, label, name, control, isMultiSelect }) {
  // const formattedOptions = Array.isArray(options) ? options : [];

  // const { selectedCategory, availableServices } = options;
  // const selectedOptions = availableServices[selectedCategory] || [];
  const [selectedValues, setSelectedValues] = useState([])

  useEffect(() => {
    // Clear selected values when category changes
    setSelectedValues([])
  }, [options])
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      outline: 0,
      borderRadius: 0,
      boxShadow: 0,
      border: "1px solid #000",
      ":hover": {
        ...styles[":hover"],
        outline: 0,
        border: "1px solid #000",
      },
      ":active": {
        ...styles[":active"],
        outline: 0,
        border: "1px solid #000",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      backgroundColor: "#FFB400",
      paddingTop: "0",
      paddingBottom: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? "#FFB400" : isFocused ? "#FFB400" : "#FFB400",
        color: isSelected ? "black" : "black",
        borderRadius: isDisabled ? 0 : isSelected ? 0 : isFocused ? 0 : 0,
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        outline: 0,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? (isSelected ? "#FFB400" : "#FFB400") : undefined,
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: !isDisabled ? (isSelected ? "#FFB400" : "#c1c1c1") : undefined,
        },
      }
    },
  }
  return (
    <div className="w-full">
      {label && <label className="font-RubikFont text-black inline-block mb-1">{label}</label>}
      <Controller
        name={name || "multiselect"}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <ReactSelect
            isMulti={isMultiSelect || true}
            options={options}
            styles={colourStyles}
            components={{ Option }}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            isSearchable={false}
            onChange={(selectedOptions) => {
              onChange(selectedOptions)
              setSelectedValues(selectedOptions)
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              backgroundColor: "#FFB400",
            })}
            // onMenuClose={() => {
            //   // Clear selected values when the menu is closed
            //   setSelectedValues([]);
            // }}
            value={selectedValues}
          ></ReactSelect>
        )}
      ></Controller>
    </div>
  )
}

export default MultiSelect
