import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import UserTestimonials from '../components/ElevatorComponents/UserTestimonials'
// import ProjectItem from "../components/ProjectItem";
import SolarPanelHero from "../assets/images/Construction/img3.webp";
import SolarPanelWorking from "../components/Construction/SolarPanelWorking";
import ConstructionAbout from "../components/Construction/ConstructionAbout";
import Button from "../components/Button";
import UserReviews from "../components/MaintenanceComponents/UserReviews";

const users = [
  {
    description:
      "“Kardaan impresses with top-notch construction, maintenance, and more. Their user-friendly app ensures quick services. Skilled team, blending tradition with technology, sets exceptional standards.”",
    name: "Hammad Shehbaz",
  },
  {
    description:
      "“The team at Kardaan was skilled, courteous, and respectful. The professionalism displayed by staff, from project managers to on-site workers, contributed to a positive and collaborative environment.”",
    name: "Faizan Mehdi",
  },
  {
    description:
      "“Kardaan services are efficient, reliable, and customer-oriented, consistently offering quality solutions tailored to diverse needs. Highly recommended for ultimate satisfaction and exceptional results.”",
    name: "Afhan Akhtar",
  },
  {
    description:
      "Kardaan Services delivers unmatched excellence, providing comprehensive, innovative, and high-quality solutions in construction and maintenance. Choose Kardaan for satisfaction beyond the ordinary.",
    name: "Faheem Aqeel",
  },
];
const ConstructionDetails = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  // const projects = [];

  return (
    <>
      {/* hero */}
      <div className="w-full bg-white flex  min-h-[524px] lg:min-h-[490px] border-t">
        <div className="w-[100%] md:w-3/6 lg:w-2/5  flex flex-col items-start text-left md:items-start md:text-left  md:pl-10 lg:pl-24 mb-10 md:mb-12 lg:mb-14 justify-center pl-10  md:justify-end ">
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Solar Energy
          </span>
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            for your Home &
          </span>
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Business
          </span>
          <p className="text-[#282828] opacity-[0.50] text-[22px] md:text-[19px] lg:text-[24px] font-normal leading-[1.3] mt-[20px] break-words font-SFProDisplayFont w-[100%] md:px-0 text-left md:text-left">
            Welcome to Kardaan, where sustainable energy meets cutting-edge
            solutions. Join us in harnessing the power of the sun for a brighter
            and greener future.
          </p>
          <Link to="/quote-form">
            <div className="mt-[20px]">
              <Button
                bgColor="bg-[#282828]"
                textColor="text-white"
                className=" border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
              >
                {" "}
                GET OUR SERVICE
              </Button>
            </div>
          </Link>
        </div>
        <div className="hidden md:block md:w-3/6 lg:w-3/5 relative">
          <img
            className="w-full object-cover absolute bottom-0"
            src={SolarPanelHero}
            alt=""
          />
        </div>
      </div>
      {/* hero */}
      <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-center items-center p-4 md:p-0 lg:p-0 xl:p-0 2xl:p-0">
        {/* Box 1: Commercial Solution */}
        <div className="solution-box bg-[#FFB400] p-4 md:p-8 lg:p-[55px] xl:p-[65px] 2xl:p-[55px] w-full md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 md:w-full lg:w-[33.33%] xl:w-[33.33%] 2xl:w-[33.33%]">
          <p className="text-[#282828] text-3xl font-SFProDisplayFont font-medium leading-[34.18px] text-center">
            Commercial <span className="inline-block md:block">Solution</span>
          </p>
        </div>

        {/* Box 2: Residential Solution */}
        <div className="solution-box bg-[#FFC845] p-4 md:p-8 lg:p-[55px] xl:p-[65px] 2xl:p-[55px] w-full md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0 md:w-full lg:w-[33.33%] xl:w-[33.33%] 2xl:w-[33.33%]">
          <p className="text-[#282828] text-3xl font-SFProDisplayFont font-medium leading-[34.18px] text-center">
            Residential <span className="inline-block md:block">Solution</span>
          </p>
        </div>

        {/* Box 3: Utility Scale */}
        <div className="solution-box bg-[#FFB400] p-4 md:p-8 lg:p-[55px] xl:p-[65px] 2xl:p-[55px] w-full md:w-full lg:w-[33.33%] xl:w-[33.33%] 2xl:w-[33.33%]">
          <p className="text-[#282828] text-3xl font-SFProDisplayFont font-medium leading-[34.18px] text-center">
            Utility <span className="inline-block md:block">Scale</span>
          </p>
        </div>
      </div>
      <ConstructionAbout />

      <SolarPanelWorking />
      {/* <div className="bg-[#efefef] py-20">
        <div className="projects-main w-[85%] mx-auto">
          <h3 className="text-center m-auto text-[40px] font-bold after:content-[''] after:w-[5%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block mb-8">
            Latest Projects
          </h3>
          <ProjectItem projects={projects} showButton />
        </div>
      </div> */}
      <UserReviews
        reviews={users}
        heading="What our customers have to say?"
        bgColor={false}
      />
    </>
  );
};

export default ConstructionDetails;
