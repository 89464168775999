import React from "react";
import BlogOneImage from "../../assets/images/Blogs-Images/Kardaan1.jpg";
import BlogTwoImage from "../../assets/images/Blogs-Images/Kardaan2.webp";
import BlogThreeImage from "../../assets/images/Blogs-Images/Kardaan3.webp";
import BlogFourImage from "../../assets/images/Blogs-Images/Kardaan4.png";
import { Link } from "react-router-dom";

const Articles = () => {
  const articleStoriesList = [
    {
      id: 0,
      imageURL: BlogOneImage,
      title: "Services at your door step",
      description:
        "Plumber, Electrician, Carpenter, Painter, AC Technician, Gardener Ya Welding ka koe b kaam ho, tu ghar bethay Kardaan App pe order place karen aur ...",
      details: "Read More...",
      category: "door-step",
    },
    {
      id: 1,
      imageURL: BlogTwoImage,
      title: "Which Service Provider are you Looking For??",
      description:
        "Kardaan is a mobile app through which you can directly connect with nearby skilled service providers (Electrician, Plumbers, Carpenter, Painter, He...",
      details: "Read More...",
      category: "service-provider",
    },
    {
      id: 2,
      imageURL: BlogThreeImage,
      title: "Kardaan Hai Naa!",
      description:
        "Kardaan is a digital platform through which you can directly connect with nearby skilled service providers in your area and get your work done.",
      details: "Read More...",
      category: "kardaan",
    },
    {
      id: 3,
      imageURL: BlogFourImage,
      title: "Kardaan is a digital platform",
      description:
        "Kardaan is a digital platform through which you can directly connect with nearby skilled service providers in your area and get your work done.",
      details: "Read More...",
      category: "digital-platform",
    },
  ];

  return (
    <>
      <div
        className="article-section px-4 sm:px-8 md:px-16 lg:px-[110px] xl:px-[110px] 2xl:px-[110px] pt-8 pb-12 lg:pt-12"
        id="home-blog"
      >
        <div className="article-heading lg:flex md:flex-wrap lg:justify-center lg:items-center">
          <h2 className="font-SFProDisplayFont text-[#282828] text-2xl md:text-3xl lg:text-4xl font-bold capitalize break-words relative after:content-[''] after:h-[5px!important] after:m-auto after:mt-[24px] after:mb-[30px] lg:after:w-[12%] sm:after:w-[6%] after:w-[10%] after:block after:bg-[color:var(--mycolor)] text-center">
            Article Stories and More
          </h2>
        </div>
        <div className="articles-list grid sm:gap-6 gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
          {articleStoriesList.map((item, id) => (
            <div
              key={id}
              className="article-item w-full md:w-[95%] m-auto lg:w-full bg-[#efefef]"
            >
              <div className="h-[240px]">
                <img className="w-full h-full" src={item.imageURL} alt="" />
              </div>
              <div className="article-text flex flex-col justify-between sm:px-6 px-2 pt-6 h-[200px] pb-8">
                <div>
                  <h4 className="text-[#282828] text-[14px] md:text-[14.21px] lg:text-[17px] 2xl:text-[19px] sm:text-[12px] font-bold font-SFProDisplayFont leading-[1.20] break-words">
                    {item.title}
                  </h4>
                  <p className="article-description font-SFProDisplayFont text-[#282828] text-[11.85px] lg:text-[13.5px] 2xl:text-[14.85px] font-normal leading-[14px] break-words tracking-[1px] mt-2">
                    {item.description}
                  </p>
                </div>
                {/* <Link to="/blogs">
                                    <p className="article-details font-SFProDisplayFont active:text-[#282828] text-sm md:text-base lg:text-lg xl:text-lg 2xl:text-lg font-medium underline text-[#FFB400] sm:leading-[4.20] leading-[1] break-words ">
                                        {item.details}
                                    </p>
                                </Link> */}
                <Link to={`/blogs/${item.category}`}>
                  <p className="article-details font-SFProDisplayFont active:text-[#282828] text-sm md:text-base lg:text-lg xl:text-lg 2xl:text-lg font-medium underline text-[#FFB400] sm:leading-[4.20] leading-[1] break-words hover:text-blue-700">
                    {item.details}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="explore flex items-center justify-center mt-12">
          <Link to={`/blogs/Service-Provider`}>
            <button className="explore-btn cursor-pointer px-12 py-3 border-4 border-solid border-[#1F1F1F] bg-transparent text-[#1F1F1F] sm:text-lg text-[1rem] font-bold tracking-[0.60px] font-SFProDisplayFont break-words hover:text-white hover:bg-[#1F1F1F]">
              EXPLORE MORE
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Articles;
