import React from "react";
import Electrician from "../../assets/images/Skills-Verification/Electrician.svg";
import Plumber from "../../assets/images/Skills-Verification/Plumber.svg";
import Technician from "../../assets/images/Skills-Verification/Technician.svg";
import Carpenter from "../../assets/images/Skills-Verification/Carpenter.svg";
import Gardener from "../../assets/images/Skills-Verification/Gardener.svg";
import Painter from "../../assets/images/Skills-Verification/Painter.svg";
import ServiceType from "./ServiceType";

const MaintenanceServices = () => {
  const serviceTypes = [
    {
      id: 0,
      imageUrl: Electrician,
      title: "Electrician",
      imageDescription:
        "Keep your family safe! Take extra pre-caution if you're having electricity issues, lives are precious; Kardaan Electricians are precious Kardaan Electricians at your service.",
    },
    {
      id: 1,
      imageUrl: Plumber,
      title: "Plumber",
      imageDescription:
        "Water is crucial for human survival, every drop saved by every person matters. Any leaky pipes around your home or office? Kardaan Plumbers are there to fix.",
    },
    {
      id: 2,
      imageUrl: Technician,
      title: "AC/Technician",
      imageDescription:
        "A/C not working in a hot summer noon? You're A/C might just need a service. Find out with Kardaan A/C Techincians.",
    },
    {
      id: 3,
      imageUrl: Carpenter,
      title: "Carpenter",
      imageDescription:
        "Give your old furniture a fresh and polished look. Appreciate your memories with Kardaan; Kardaan Carpenters are there to help!",
    },
    {
      id: 4,
      imageUrl: Gardener,
      title: "Gardener",
      imageDescription:
        "Maintain your beautiful Garden or get one made or your rooftop. Kardaan Gardener is here for you!",
    },
    {
      id: 5,
      imageUrl: Painter,
      title: "Painter",
      imageDescription:
        "Who doesn't love the feeling of looking at the freshly painted walls of their room? Kardaan Painters; ready when you are!",
    },
  ];

  return (
    <>
      <div
        id="maintenance-services"
        className="bg-[color:#EFEFEF] lg:px-[110px] md:px-[50px] pt-[35px] pb-[30px]"
      >
        <h2 className="text-center text-[color:black] sm:text-[36px] text-[25px] font-SFProDisplayFont font-bold leading-[1.5] after:content-[''] sm:after:w-[8.5%] after:w-[12%] after:m-auto after:my-[18px] after:h-[5px] after:bg-[color:var(--mycolor)] after:block">
          Maintenance Services
        </h2>
        <div
          id="service-types"
          className="grid gap-6 p-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3"
        >
          {serviceTypes.map((service) => (
            <ServiceType key={service.id} {...service} />
          ))}
        </div>
      </div>
    </>
  );
};

export default MaintenanceServices;
