import React from "react";

const ServiceType = ({ id, imageUrl, imageDescription, title }) => {
  return (
    <div
      key={id}
      className="w-[calc(373px - 8px)] h-[276px] bg-white rounded-lg text-center lg:p-[22px] p-[37px]"
    >
      <img className="m-auto w-[83px] h-[83px]" src={imageUrl} alt={title} />
      <h3 className="text-[color:black] text-[24.41px] font-SFProDisplayFont font-medium leading-[1.8] break-words">
        {title}
      </h3>
      <p className="text-[color:#666666] opacity-[0.80] text-[12.69px] font-SFProDisplayFont font-normal leading-[1.5] tracking-[0.51px] break-words">
        {imageDescription}
      </p>
    </div>
  );
};

export default ServiceType;
