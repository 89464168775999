import React from "react";
import ImageOne from "../../assets/images/Team-Images/FirstImage.png";
import ImageTwo from "../../assets/images/Team-Images/SecondImage.png";
import ImageThree from "../../assets/images/Team-Images/ThirdImage.png";
import ImageFour from "../../assets/images/Team-Images/FourthImage.png";
import FifthImage from "../../assets/images/Team-Images/FifthImage.png";
// import Avatar from "../../assets/images/Team-Images/avatar.png";
import SixthImage from "../../assets/images/Team-Images/SixthImage.png";

const Team = () => {
  const teamImages = [
    {
      id: 0,
      imageURL: ImageOne,
      role: "Managing Director & Founder",
      name: "Malik Ahsan",
    },
    {
      id: 1,
      imageURL: ImageTwo,
      role: `Director Entrepreneurship \n & Co - Founder`,
      name: "Wasim Javed",
    },
    {
      id: 2,
      imageURL: ImageThree,
      role: "CEO & Co - Founder",
      name: "Hammad Lodhi",
    },
    {
      id: 3,
      imageURL: ImageFour,
      role: "Director Operations \n (Elevator Services)",
      name: "Jamal Shah",
    },
    {
      id: 4,
      imageURL: FifthImage,
      role: " Chief Operating Officer \n (Construction Services)",
      name: "Malik Qasim Ali",
    },
    {
      id: 5,
      imageURL: SixthImage,
      role: "Director Marketing \n (Elevator Services)",
      name: "Fahad Latif Abbasi",
    },
  ];

  // Custom SplitText component
  const SplitText = ({ text }) => {
    const lines = text.split("\n");
    return (
      <>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < lines.length - 1 && <br />}{" "}
          </React.Fragment>
        ))}
      </>
    );
  };

  // const SplitText = ({ text }) => {
  //   const lines = text.split("\n");
  //   return (
  //     <>
  //       {lines.map((line, index) => (
  //         <p key={index}>{line}</p>
  //       ))}
  //     </>
  //   );
  // };

  return (
    <>
      <div
        className="team-section  flex flex-col sm:px-12 px-6 py-12 lg:p-20 justify-center bg-[color:var(--mycolor)]"
        id="home-team"
      >
        <div className="team-intro">
          <h2 className="font-SFProDisplayFont text-center text-[#282828] sm:text-4xl text-[28px] font-bold capitalize tracking-wider break-words">
            Meet Our Team
          </h2>
          <p className="font-SFProDisplayFont m-auto mt-[15px] md:max-w-[75%] text-center text-[#282828] text-base font-normal leading-relaxed break-words">
            "Our presence ensures Safety, Quality, and Sustainability. With
            upgraded elevator and lift installation services, we make sure to
            save our client's investments in the long run by improving
            accessibility, aesthetics, and illumination options, while
            increasing comfort and decreasing the operational cost."
          </p>
        </div>
        <div className="team-images flex justify-center flex-wrap w-full md:gap-2 sm:gap-6 gap-4 mt-7">
          {teamImages.map((item, index) => (
            <div
              key={item.id}
              className="image md:w-[28%] lg:w-[20%] xl:w-[16%] flex flex-col items-center"
            >
              <div>
                <img
                  className="w-full mt-[15px] object-cover"
                  src={item.imageURL}
                  alt={item.role}
                />
              </div>
              <h3 className="font-SFProDisplayFont text-black text-center text-lg mt-[8px] font-bold break-words">
                {item.name}
              </h3>
              {/* Use the custom SplitText component for role */}
              <p className="role-description font-SFProDisplayFont text-black text-center text-[12.50px] font-medium capitalize break-words">
                <SplitText text={item.role} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Team;
