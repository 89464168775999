// function Reviews({ review }) {
//   return (
//     <div>
//       <div className="bg-[color:white]  h-[460px] text-black rounded-xl py-[25px] px-[20px] flex flex-col items-center justify-between gap-4">
//         <div className="flex flex-col items-center justify-center gap-4">
//           <p
//             id="user-description"
//             className="text-[color:#666666] text-center text-[20px]  sm:text-[16px] font-SFProDisplayFont font-normal leading-normal tracking-wide opacity-[0.80] break-words"
//           >
//             {review?.description}
//           </p>
//         </div>
//         <h3
//           id="user-name"
//           className="text-center text-black text-[26.85px] font-SFProDisplayFont font-medium leading-[1.4] break-words"
//         >
//           {review?.name}
//         </h3>
//       </div>
//     </div>
//   );
// }

// export default Reviews;

function Reviews({ review }) {
  return (
    <div className="user-review bg-white h-[490px] text-black  py-[25px] px-[20px] flex flex-col items-center justify-between gap-4 ">
      <div
        className="rating flex flex-col justify-between items-center w-full  "
        // style={{ maxHeight: "391px" }}
      >
        <div className="icons flex justify-evenly items-center w-4/5">
          <i className="fas fa-star checked text-[orange] text-[24px]"></i>
          <i className="fas fa-star checked text-[orange] text-[24px]"></i>
          <i className="fas fa-star checked text-[orange] text-[24px]"></i>
          <i className="fas fa-star checked text-[orange] text-[24px]"></i>
          <i className="fas fa-star checked text-[orange] text-[24px]"></i>
        </div>
        <div className="testimonial-description text-justify mt-5 h-[250px] overflow-y-scroll ">
          <p className="font-SFProDisplayFont text-base text-[#666666] font-normal leading-[27px] tracking-[1.0px] break-words text-left ">
            {review.description}
          </p>
        </div>
      </div>
      <h4 className="font-SFProDisplayFont text-[#2A2A2A] text-[16px] font-medium self-start leading-[50.40px] tracking-[0.32px] break-words">
        {review.name}
      </h4>
    </div>
  );
}

export default Reviews;
