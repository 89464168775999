import React from "react";
import Button from "../Button";
import PdfProfile from "../../assets/Docs/kardaan-profile.pdf";
import ExperienceImage from "../../assets/images/Elevator-Images/Overview.jpg";
const ExperienceOverview = ({
  imgUrl = "",
  heading = "",
  button = "",
  subHeading = "",
  description = "",
}) => {
  const handleButtonClick = () => {
    const pdfFilePath = PdfProfile;
    window.open(pdfFilePath, "_blank");
  };
  return (
    <div className="min-w-full bg-[color:#EFEFEF] py-8">
      <div className="flex flex-col md:flex-row gap-10 w-[90%] mx-auto">
        <div className="w-full md:w-2/4 flex justify-center lg:justify-end">
          <img
            src={ExperienceImage}
            className="block object-cover h-[500px] md:h-auto"
            alt=""
          />
        </div>
        <div className="w-full md:w-2/4 min-h-[420px] md:min-h-[620px] flex flex-col">
          <div>
            <h2 className="text-black text-[36.28px] md:text-[35.28px] lg:text-[49.28px] font-SFProDisplayFont font-bold leading-[1.1] break-words">
              <span className="block">Delivering</span> Excellence, Elevating
              your experience
            </h2>
          </div>
          <div className="mt-[20px] flex flex-col">
            <div>
              <p className="text-[#282828] opacity-[0.50] text-[18px] lg:text-[20px] font-SFProDisplayFont font-normal leading-[1.4] break-words">
                We are a leading professional elevator company, we guide you
                through every phase of your project, from conceptual design to
                seamless production, expert installation, and reliable
                after-sales services. Our dedicated team combines
                state-of-the-art equipment with a highly skilled workforce,
                bringing a wealth of diversified experience that spans the
                entire spectrum of elevator and lift development.
              </p>
              <div
                className="flex
               md:gap-0  gap-4 w-full py-[5px] lg:py-[20px]"
              >
                <div className="w-[50%] flex justify-between items-center">
                  <div className="profile-text flex  lg:flex-row items-center lg:flex-nowrap flex-wrap gap-1 lg:gap-6">
                    <p className="text-[49px] font-bold leading-[1.4] tracking-[0em] text-left text-[color:#FFB400]">
                      5+
                    </p>

                    <p className="text-[18px] font-SFProDisplayFont font-medium leading-[1.4] tracking-[0em] text-left">
                      Years
                      <br />
                      Experience
                    </p>
                  </div>
                </div>
                <div className="w-[50%] flex items-center">
                  <div className="profile-text flex items-center lg:flex-nowrap flex-wrap gap-1 lg:gap-6">
                    <span className="text-[49px] font-bold leading-[1.4] tracking-[0em] text-left text-[color:#FFB400]">
                      50+
                    </span>

                    <span className="text-[18px] font-SFProDisplayFont font-medium leading-[1.4] tracking-[0em] text-left">
                      Projects
                      <br />
                      Completed
                    </span>
                  </div>
                </div>
              </div>

              <p className="text-[#282828] opacity-[0.50] text-[18px]  lg:text-[20px] font-SFProDisplayFont font-normal leading-[1.4] break-words">
                Discover the superior quality and performance of our elevators,
                available in two distinct types: Imported Elevators and
                Semi-Imported Elevators. Additionally, we proudly offer branded
                elevator services featuring the renowned OTS TEC.
              </p>
            </div>

            <div className="mt-[20px] lg:mt-[27px]">
              <Button
                onClick={handleButtonClick}
                bgColor="bg-[#282828]"
                textColor="text-white"
                className="w-44 py-3"
              >
                {" "}
                PDF PROFILE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceOverview;
