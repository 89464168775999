import React from "react"
import ReactSelect, { components } from "react-select"
import { Controller } from "react-hook-form"

// const Option = (props) => (
//   <components.Option {...props}>
//     <label>{props.data.label}</label>
//   </components.Option>
// );
const Option = (props) => (
  <components.Option {...props}>
    <label>{props.data?.label}</label>
  </components.Option>
)

function Select({ initialValue, options, label, name, control, onChangeHandler = () => {} }) {
  // Convert array of strings to array of objects
  // const formattedOptions = options.map((value) => ({ value, label: value }));
  // console.log("Formatted Options:", formattedOptions);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      outline: 0,
      borderRadius: 0,
      boxShadow: 0,
      border: "1px solid #000",
      ":hover": {
        ...styles[":hover"],
        outline: 0,
        border: "1px solid #000",
      },
      ":active": {
        ...styles[":active"],
        outline: 0,
        border: "1px solid #000",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      backgroundColor: "#FFB400",
      paddingTop: "0",
      paddingBottom: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? "#FFB400" : isFocused ? "#FFB400" : "#FFB400",
        color: isSelected ? "black" : "black",
        borderRadius: isDisabled ? 0 : isSelected ? 0 : isFocused ? 0 : 0,
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        outline: 0,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? (isSelected ? "#FFB400" : "#FFB400") : undefined,
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: !isDisabled ? (isSelected ? "#FFB400" : "#c1c1c1") : undefined,
        },
      }
    },
  }

  return (
    <div className="w-full">
      {label && <label className="font-RubikFont text-black inline-block mb-1">{label}</label>}
      <Controller
        name={name || "singleSelect"}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <ReactSelect
            isMulti={false}
            options={options}
            styles={colourStyles}
            components={{ Option }}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isSearchable={false}
            onChange={(selectedOption) => {
              onChange(selectedOption)
              onChangeHandler(selectedOption.value)
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              backgroundColor: "#FFB400",
            })}
          ></ReactSelect>
        )}
      ></Controller>
    </div>
  )
}

export default Select
