import React from "react"

function ServiceItem({ imgUrl = "", heading = "", content = "", className = "", headingSize = "", contentSize = "" }) {
  return (
    <div className={` ${className} h-full`}>
      <div className="bg-white drop-shadow-md w-[100%]">
        <div className="flex w-full">
          <div>
            <img className="min-w-[160px] min-h-[264px] w-full object-cover" src={imgUrl} alt="" />
          </div>
          <div className="pl-[18px] pr-[10px] flex flex-col justify-center">
            <h2 className={`text-black break-words pb-[10px] pr-[15px] leading-[21.22px] font-medium font-SFProDisplayFont ${headingSize}`}>
              {heading}
            </h2>
            <ul className="pl-[18px]">
              <li
                className={`text-[color:#666666] text-[15.38px] opacity-[0.70] font-SFProDisplayFont font-normal break-words list-disc leading-[16.92px] tracking-wider ${contentSize}`}
              >
                {content}
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
