import React from "react";
import { Link } from "react-router-dom";

const Quote = () => {
  return (
    <>
      {/* Quote Main */}
      {/* <div
        className="quote flex w-full flex-wrap justify-center items-center bg-white 
            xl:p-[30px] lg:p-[30px] md:p-[25px] sm:p-[15px] p-[10px]
            "
      > */}
      <div className="quote flex flex-col w-full flex-wrap justify-center items-center bg-white xl:p-[30px] lg:p-[30px] md:p-[25px] sm:p-[15px] p-[15px] sm:flex-row md:flex-row lg:flex-row xl:flex-row">
        <div className="quote-text sm:w-[60%] w-[85%]">
          <p
            className="text-[var(--sub-heading-color)] font-SFProDisplayFont  
                    2xl:text-[40px] xl:text-[34px] lg:text-[32px] md:text-[30px] text-[20px]
                    font-medium leading-6 text-center"
          >
            Get a Quote For Your Project
          </p>
        </div>
        {/* Quote Button */}
        <div className="quote-btn sm:w-[40%] w-[42%] sm:text-start sm:block flex justify-center items-center">
          <Link to="/quote-form">
            <button className="sm:border-4 border-[3px] border-solid border-[var(--mycolor)] text-[var(--mycolor)] font-SFProDisplayFont cursor-pointer py-[6px] px-[10px] 2xl:px-[40px] 2xl:py-[12px] xl:py-[12px] xl:px-[50px] md:py-[8px] md:px-[40px] sm:py-[8px] sm:px-[30px] bg-transparent text-[13px] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20px] 2xl:m-0 xl:m-0 lg:m-0 md:m-0 sm:m-0 mt-[10px] font-bold tracking-[0.60px] break-words hover:bg-[var(--mycolor)] hover:text-[#000000]">
              GET A QUOTE
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Quote;
