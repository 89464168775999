import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import ConstructionComponent from "../components/Construction/ConstructionComponent"

function Construction() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="w-full">
      <ConstructionComponent />
    </div>
  )
}

export default Construction
