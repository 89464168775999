import React, { useState } from "react";
import RemodelingImage from "../../assets/images/Rennovation-Images/InteriorRemodeling.png";
import ExteriorImage from "../../assets/images/Rennovation-Images/ExteriorRemodeling.png";
import HouseRenovationImage from "../../assets/images/Rennovation-Images/HouseRenovation.png";
import AdditionsImage from "../../assets/images/Rennovation-Images/Additions.png";
import CarpentryImage from "../../assets/images/Rennovation-Images/Carpentry.svg";
import FlooringImage from "../../assets/images/Rennovation-Images/Flooring.png";
import PaintingImage from "../../assets/images/Rennovation-Images/Painting.png";
import ElectricalImage from "../../assets/images/Rennovation-Images/Electrical.png";
import EnergyImage from "../../assets/images/Rennovation-Images/Energy.png";
import StructuralImage from "../../assets/images/Rennovation-Images/Structural.png";
import CommercialImage from "../../assets/images/Rennovation-Images/Commerical.png";
import KitchenImage from "../../assets/images/Rennovation-Images/Kitchen.png";
import BathroomImage from "../../assets/images/Rennovation-Images/Bathroom.png";
import InteriorImage from "../../assets/images/Rennovation-Images/Interior.png";

const OurServiceItem = ({ imgUrl, serviceTitle, serviceDetails }) => (
  // <div className="flex ">
  //   <div className="w-2/5">
  //     <img className="h-full object-cover" src={imgUrl} alt="" />
  //   </div>
  //   <div className="service-text w-3/5 pl-[40px] pr-[27px] pt-[40px] bg-white">
  //     <h2 className="text-black text-[27.82px] font-SFProDisplayFont font-medium leading-[1.2] break-words">
  //       {serviceTitle}
  //     </h2>
  //     <ul className="text-[color:#666666] opacity-[0.80] text-[15.45px] font-SFProDisplayFont font-normal leading-[1.2] tracking-[0.62px] break-words list-disc">
  //       {serviceDetails.split("\n").map((detail, index) => (
  //         <li key={index}>{detail}</li>
  //       ))}
  //     </ul>
  //   </div>
  // </div>
  <div className={`w-full md:max-w-[48%] lg:max-w-[30%] xl:max-w-md`}>
    <div className="bg-white drop-shadow-md w-[100%]">
      <div className="flex w-full">
        <div className="w-2/6 overflow-hidden">
          <img
            className="max-w-[160px] min-h-[264px] w-full object-cover"
            src={imgUrl}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center items-start w-2/3">
          <h2
            className={`text-black pb-[10px] font-medium font-SFProDisplayFont text-[27px] leading-[28px] w-3/4 mx-auto`}
          >
            {serviceTitle}
          </h2>

          <ul className="text-[color:#666666] opacity-[0.80] text-[15.45px] font-SFProDisplayFont font-normal leading-[1.2] tracking-[0.62px] list-disc w-[63%] mx-auto">
            {serviceDetails.split("\n").map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

const OurServices = () => {
  const [showMore, setShowMore] = useState(false);
  const servicesList = [
    {
      imgUrl: RemodelingImage,
      serviceTitle: "Interior Remodeling",
      serviceDetails:
        "Kitchen remodeling\nBathroom remodeling\nLiving room or bedroom renovations",
    },
    {
      imgUrl: ExteriorImage,
      serviceTitle: "Exterior Remodeling",
      serviceDetails:
        "Roofing\nWindow and door installation\nExterior painting and landscaping",
    },
    {
      imgUrl: HouseRenovationImage,
      serviceTitle: "Whole House Renovation",
      serviceDetails:
        "Comprehensive home renovations that involve both interior and exterior updates.",
    },
    {
      imgUrl: AdditionsImage,
      serviceTitle: "Additions",
      serviceDetails: "Room additions (bedrooms, bathrooms, living spaces)",
    },
    {
      imgUrl: CarpentryImage,
      serviceTitle: "Custom Carpentry",
      serviceDetails:
        "Custom-built cabinetry\nBuilt-in shelving and storage solutions",
    },
    {
      imgUrl: FlooringImage,
      serviceTitle: "Flooring Services",
      serviceDetails:
        "Hardwood floor installation and refinishing\nTile, laminate, or vinyl flooring installation\nCarpet replacement",
    },
    {
      imgUrl: PaintingImage,
      serviceTitle: "Painting & Wall Covering",
      serviceDetails:
        "Interior and exterior painting\nWallpaper installation or removal",
    },
    {
      imgUrl: ElectricalImage,
      serviceTitle: "Electrical & Plumbing",
      serviceDetails:
        "Electrical system upgrades or repairs\nPlumbing system upgrades or repairs",
    },
    {
      imgUrl: EnergyImage,
      serviceTitle: "Energy Efficiency Upgrades",
      serviceDetails:
        "Window replacement for energy efficiency\nSolar panel installation",
    },
    {
      imgUrl: StructuralImage,
      serviceTitle: "Structural Repairs",
      serviceDetails:
        "Foundation repair\nRoof repairs\nWall and ceiling repairs",
    },
    {
      imgUrl: CommercialImage,
      serviceTitle: "Commercial Renovations",
      serviceDetails:
        "Renovation services for commercial spaces, such as offices, retail stores, and restaurants.",
    },
    {
      imgUrl: KitchenImage,
      serviceTitle: "Kitchen Upgrades",
      serviceDetails:
        "Cabinet Refacing\nCountertop Replacement\nFixture and Appliance Installation",
    },
    {
      imgUrl: BathroomImage,
      serviceTitle: "Bathroom Makeovers",
      serviceDetails:
        "Tile and Grout Renewal\nVanity and Sink Replacement\nBathtub or Shower Refinishing",
    },
    {
      imgUrl: InteriorImage,
      serviceTitle: "Interior",
      serviceDetails:
        "Wall and Ceiling Painting\nTrim and Molding Painting\nColor Consultation\nFlooring and Lighting Updates",
    },
  ];

  return (
    <div className="pt-[35px] pb-[50px] md:px-[20px] sm:px-[90px] px-6 bg-[color:#EFEFEF]">
      <div>
        <h2 className="text-center text-[color:black] text-[36px] font-SFProDisplayFont font-bold leading-[2] ">
          Our Services
        </h2>

        <p className="text-center text-[color:#8B8B8B] text-[22px] sm:p-0 p-4 md:w-[55%] w-full m-auto font-SFProDisplayFont font-normal leading-[1.4] break-words after:content-[''] sm:after:w-[12%] after:w-[15%] after:m-auto after:mt-[25px] after:h-[5px] after:bg-[#FFB400] after:block">
          From concept to completion, our skilled team will breathe new life
          into your home or office through our top-notch renovation solutions.
        </p>
      </div>

      <div className="flex flex-wrap w-full gap-4 lg:gap-6 justify-center mt-8 ">
        {showMore
          ? servicesList.map(
              (service, index) =>
                index <= servicesList.length && (
                  <OurServiceItem key={index} {...service} />
                )
            )
          : servicesList.map(
              (service, index) =>
                index <= 5 && <OurServiceItem key={index} {...service} />
            )}
      </div>

      <div className="view-all-btn uppercase flex items-center justify-center mt-[50px] w-full">
        <button
          className="border-[4px] border-solid border-[color:var(--button-border-color)] text-[color:var(--button-border-color)] cursor-pointer px-[60px] py-[10px] bg-transparent text-[20px] font-bold tracking-[0.60px] break-words hover:bg-[color:var(--button-border-color)] hover:text-[color:white]"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show Less" : "View All"}
        </button>
      </div>
    </div>
  );
};

export default OurServices;
