import React from "react";
import ClientImageOne from "../../assets/images/Clients-Images/1.png";
import ClientImageTwo from "../../assets/images/Clients-Images/2.png";
import ClientImageThree from "../../assets/images/Clients-Images/3.png";
import ClientImageFour from "../../assets/images/Clients-Images/4.png";
import ClientImageFive from "../../assets/images/Clients-Images/5.png";
import ClientImageSix from "../../assets/images/Clients-Images/6.png";
import ClientImageSeven from "../../assets/images/Clients-Images/7.png";
import ClientImageEight from "../../assets/images/Clients-Images/8.png";
import ClientImageNine from "../../assets/images/Clients-Images/9.png";
//Imports for Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clients = () => {
  const clientImage = [
    { imageUrl: ClientImageOne },
    { imageUrl: ClientImageTwo },
    { imageUrl: ClientImageThree },
    { imageUrl: ClientImageFour },
    { imageUrl: ClientImageFive },
    { imageUrl: ClientImageSix },
    { imageUrl: ClientImageSeven },
    { imageUrl: ClientImageEight },
    { imageUrl: ClientImageNine },
  ];

  //Images Sliding for multiple screen widths
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Set the speed of the slide animation
    slidesToShow: 7, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Set the autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div>
      <h2 className="font-SFProDisplayFont text-center mt-[20px] sm:text-[40px] text-[30px] text-[color:#2A2A2A] font-bold after:content-[''] sm:after:w-[10%] after:w-[12%] lg:after:w-[5%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block after:mt-[20px] after:mb-[25px]">
        Our Clients
      </h2>
      <div className="flex flex-wrap justify-center items-center mt-4 sm:mb-10 mb-8">
        <Slider {...settings} className="h-auto w-full ">
          {clientImage.map((client, index) => (
            <div key={index}>
              <img
                src={client.imageUrl}
                alt=""
                className="md:h-[104px] md:w-[104px] sm:h-[96px] sm:w-[96px] h-20 w-20 mt-2 inline-block"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
