import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { scroller } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import Kardaan from "../../assets/images/Navbar-Images/Kardaan.svg";

function Navbar() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const scrollToSection = params.get("scrollTo");
  const [isOpen, setIsOpen] = useState(false);

  const isHomePage = location.pathname === "/"; // Check if the current page is the home page

  const navItems = [
    { title: "Home", pathId: "home" },
    { title: "About", pathId: "home-about" },
    { title: "Projects", pathId: "home-projects" },
    { title: "Team", pathId: "home-team" },
    { title: "Blog", pathId: "home-blog" },
    { title: "Contact", pathId: "home-contact" },
  ];
  useEffect(() => {
    if (scrollToSection) {
      let offsetValue = 600; // default offset

      // Check if the scrollToSection matches specific values
      if (
        scrollToSection === "home" ||
        scrollToSection === "home-about" ||
        scrollToSection === "home-projects"
      ) {
        offsetValue = -100;
      }

      scroller.scrollTo(scrollToSection, {
        duration: 600,
        offset: offsetValue,
        smooth: true,
      });
    }
  }, [scrollToSection]);

  // useEffect(() => {
  //   if (scrollToSection) {
  //     // Use your preferred method to scroll to the section
  //     // Example using react-scroll:
  //     scroller.scrollTo(scrollToSection, {
  //       duration: 600,
  //       offset: 600,
  //       smooth: true,
  //     });
  //   }
  // }, [scrollToSection]);

  return (
    <div className="w-full sticky top-0 left-0 right-0 z-[999!important]">
      <div>
        <nav className="bg-white shadow-md">
          <div className="max-w-screen-xl w-full xl:w-[80%] flex flex-wrap flex-row md:flex-col lg:flex-row justify-between lg:justify-around xl:justify-between items-center mx-auto py-1">
            <div className="sm:ml-[15px] md:m-0 ml-[15px] w-[22%] xl:w-[30%] md:w-[32%]">
              <Link
                to={"/"}
                className="flex md:justify-center justify-normal items-center rtl:space-x-reverse outline-none"
              >
                <img
                  src={Kardaan}
                  alt="Kardaan Logo"
                  className="w-[63px] h-[63px]"
                />
                <div className="px-2">
                  <h1 className="text-black text-[1.99rem] leading-[1] xl:text-[2.5rem]  font-InterFont font-extrabold">
                    KARDAAN
                  </h1>
                  <p className="text-[6.79px] xl:text-[8.8px] leading-[7.29px] font-bold font-MontserratFont tracking-[0.28px]">
                    BUILDING TRUST, ELEVATING STANDARDS
                  </p>
                </div>
              </Link>
            </div>
            <button
              data-collapse-toggle="navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 mr-[10px] justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                aria-hidden="true"
                className={`w-5 h-5 `}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              className={`w-full md:block md:w-[65%] ${
                isOpen ? "block" : "hidden"
              } `}
              id="navbar-default"
            >
              <ul className="font-medium flex md:justify-center justify-normal flex-col w-full md:p-4 lg:p-0 mt-4 bg-gray-50 md:flex-row rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
                {navItems.map((navItem) => (
                  <li
                    key={navItem.pathId}
                    className="md:mt-2 lg:mt-0 cursor-pointer"
                  >
                    {isHomePage ? (
                      <ScrollLink
                        to={navItem.pathId}
                        spy={true}
                        smooth={true}
                        offset={-100} // Adjust the offset if needed to properly scroll to the target
                        duration={600}
                        activeClass="active-tab"
                        className={`px-4 py-2 block hover:bg-gray-300 md:hover:bg-transparent w-full text-black text-base text-left md:text-center font-SFProDisplay font-medium`}
                        onClick={() => setIsOpen(false)}
                      >
                        {navItem.title}
                      </ScrollLink>
                    ) : (
                      // Add this for non-home page redirect and scroll
                      <Link to={`/?scrollTo=${navItem.pathId}`}>
                        <div
                          className={`px-4 py-2 block hover:bg-gray-300 md:hover:bg-transparent w-full text-black text-base text-left md:text-center font-SFProDisplay font-medium`}
                          onClick={() => setIsOpen(false)}
                        >
                          {navItem.title}
                        </div>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
