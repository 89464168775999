// import React from "react";
// import Kardaan from "../../assets/images/MaintenancePage-Images/kardaan.svg";

// const MaintenanceHeroSection = () => {
//   return (
//     <>
//       <div className=" maintenance-main bg-[#EFEFEF] pl-[140px] flex">
//         <div className="   pt-[170px] w-3/5">
//           <span className="break-words leading-[0.9] block text-black font-bold text-[49.28px]">
//             Verified, Trusted
//           </span>
//           <span className="text-black font-bold text-[49.28px] block">
//             and Skilled Handymen{" "}
//           </span>
//           <span className="text-black font-bold leading-[0.8] text-[49.28px]">
//             {" "}
//             at&nbsp;
//             <span className="text-yellow-500  break-words font-bold text-[49.28px]">
//               Your Door Step
//             </span>
//           </span>

//           <p className="text-[#282828] opacity-[0.50] text-[24px] font-normal leading-[1.3] mt-[6px] break-words font-SFProDisplayFont w-4/5">
//             Offering Reliable Maintenance Services Tailored to Your Needs
//             reliable Maintenance Services.
//           </p>
//           <div className="get-services-btn mt-[27px] flex justify-center items-center w-[31%] px-[30px] py-[14px] bg-[color:#282828]">
//             <button className="service-btn text-white text-[16px] font-SFProDisplayFont font-bold tracking-[1.60px] break-words">
//               GET OUR SERVICE
//             </button>
//           </div>
//         </div>
//         <div className="w-2/5 pt-[110px] pr-[30px]">
//           <img
//             src={Kardaan}
//             alt="Phone displaying services, mechanic with tools."
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default MaintenanceHeroSection;

import React from "react";
import Kardaan from "../../assets/images/MaintenancePage-Images/kardaan.png";
import Button from "../Button";
import { Link } from "react-router-dom";

const MaintenanceHeroSection = () => {
  return (
    <>
      {/* <div className="maintenance-main bg-[#EFEFEF] flex flex-col justify-center lg:flex-row lg:pl-[70px]">
        <div className="xl:pt-[140px] xl:pl-[110px] md:pt-[80px] sm:pt-[60px] pt-[40px] w-full md:w-full text-center text-[48px] lg:text-left leading-[0.9]">
          <span className="break-words  block text-black font-bold ">Verified, Trusted</span>
          <span className="text-black font-bold block">and Skilled Handymen </span>
          <span className="text-black font-bold ">
            {" "}
            at&nbsp;
            <span className="text-yellow-500 break-words font-bold ">Your Door Step</span>
          </span>

          <p className="text-[#282828] opacity-[0.50] text-[24px] font-normal leading-[1.3] break-words font-SFProDisplayFont w-[85%] lg:w-3/4 lg:m-0 md:w-[90%] lg:mt-[10px] m-auto">
            Offering Reliable Maintenance Services Tailored to Your Needs reliable Maintenance Services.
          </p>
          <div
            className="get-services-btn mt-[27px] flex justify-center items-center w-[55%] m-auto lg:ml-0 lg:w-[42%] md:w-[30%] xl:w-[38%] 
          sm:w-[35%] sm:px-[30px] sm:py-[14px] px-[35px] py-[10px] bg-[color:#282828]"
          >
            <button className="service-btn text-white text-[16px] font-SFProDisplayFont font-bold tracking-[1.60px] break-words">
              GET OUR SERVICE
            </button>
          </div>
        </div>

        <div className="w-[90%] sm:w-4/5 md:w-[70%] lg:m-0 md:m-auto sm:m-auto m-auto pt-[20px] md:pt-[70px]">
          <img src={Kardaan} alt="Phone displaying services, mechanic with tools." className="w-full" />
        </div>
      </div> */}
      {/* hero */}
      <div className="maintenance-hero-section-main w-full bg-[#efefef] flex items-center justify-center min-h-[524px] lg:min-h-[491px] border-t">
        <div className="maintenance-hero-section w-[100%] md:w-3/6 flex flex-col items-start text-left pl-10 md:items-start md:text-left md:pl-24 xl:pl-28">
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            Verified, Trusted
          </span>
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[35.28px] lg:text-[49.28px] ">
            and Skilled Handymen
          </span>
          <span className="leading-[1.1] text-black font-bold text-[42.28px] md:text-[38.28px] lg:text-[49.28px] ">
            at Your Door Step
          </span>

          <p className="text-[#282828] opacity-[0.50] text-[22px] md:text-[20px] lg:text-[24px] font-normal leading-[1.3] mt-[27px] break-words font-SFProDisplayFont w-[85%] text-left md:text-left">
            Offering Reliable Maintenance Services Tailored to Your Needs
            reliable Maintenance Services.
          </p>
          <div className="mt-[27px]">
            <Link to="/quote-form">
              <Button
                bgColor="bg-[#282828]"
                textColor="text-white"
                className=" border-[3px] border-white hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]"
              >
                {" "}
                GET OUR SERVICE
              </Button>
            </Link>
          </div>
        </div>
        <div className="hidden md:w-3/6 md:flex md:justify-center pt-8 h-[491px]">
          <img className="" src={Kardaan} alt="" />
        </div>
      </div>
      {/* hero */}
    </>
  );
};

export default MaintenanceHeroSection;
