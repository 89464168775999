import React from "react";
import SolarPanelImage from "../../assets/images/Construction/Rectangle332.svg";
const ConstructionAbout = () => {
  return (
    <div className="flex md:flex-row w-full flex-col justify-center md:px-[20px] lg:px-[60px] md:py-[70px] px-[30px] py-[30px] gap-10">
      <div className="w-full md:w-[50%]  flex justify-center">
        <img
          src={SolarPanelImage}
          alt=""
          className=" min-h-full object-cover"
        />
      </div>
      <div className="w-full md:w-[50%] flex flex-col justify-between ">
        <div>
          {" "}
          <h2 className="text-black text-[42.28px]  lg:text-[49.28px] leading-[1.0] font-SFProDisplayFont font-bold">
            About Us
          </h2>
          <h4 className="text-[#282828] leading-[1.0] mt-1 opacity-[0.50] text-[30px] md:text-[32px] lg:text-[38px]  font-SFProDisplayFont font-normal">
            Empowering Green Futures
          </h4>
        </div>
        <div className="h-[70%] flex flex-col justify-evenly gap-4 mt-4">
          <span className="text-[#282828] opacity-[0.50] text-[18px] font-SFProDisplayFont font-normal">
            We are committed to empowering communities through renewable energy
            solutions. With 5 years of experience, we've been a leader in
            providing sustainable and cost-effective solar solutions.
          </span>

          <span className="text-[#282828] opacity-[0.50] text-[18px] font-SFProDisplayFont font-normal">
            {" "}
            Switching to solar not only reduces your carbon footprint but also
            offers financial benefits. Enjoy lower energy bills, government
            incentives, and contribute to a sustainable future. The advantages
            of going solar are clear and impactful.
          </span>

          <span className="text-[#282828] opacity-[0.50] text-[18px] font-SFProDisplayFont font-normal">
            {" "}
            we are committed to empowering communities through renewable energy
            solutions. With 5 years of experience, we've been a leader in
            providing sustainable and cost-effective solar solutions.
          </span>

          <span className="text-[#282828] opacity-[0.50] text-[18px] font-SFProDisplayFont font-normal">
            we are committed to empowering communities through renewable energy
            solutions. With 5 years of experience, we've been a leader in
            providing sustainable and cost-effective solar solutions.
          </span>
        </div>
        {/* <div className="mt-[20px] lg:mt-[27px]">
          <button type="button" className="px-4 text-white bg-[#282828] w-44 py-3">
            SHOW MORE
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ConstructionAbout;
