import { Outlet } from "react-router-dom";
import "./App.css";
import Navbar from "./components/HomeComponents/Navbar";
import ContactUs from "./components/Footer/Footer";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <Outlet></Outlet>
      </main>
      <ContactUs />
    </div>
  );
}

export default App;
