import React from "react";

function Button({
  children,
  type = "button",
  textColor = "text-white",
  bgColor = "bg-[#282828]",
  className = "",
  ...props
}) {
  return (
    <button
      type={type}
      className={`px-6 py-2  ${textColor} ${bgColor} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
