import React from "react";

import Button from "../Button";
import { Link } from "react-router-dom";

function MaterialItem({
  title,
  description,
  imgURl,
  className = "",
  descriptionSize,
}) {
  return (
    <div className="w-[320px] lg:w-[300px] min-h-[270px]">
      <div className="flex flex-col justify-between p-2 border border-black border-opacity-20">
        <div className="w-full">
          <img src={imgURl} className="w-full object-cover" alt={title} />
        </div>
        <div className="mt-4 h-[128px] flex flex-col justify-between">
          <div>
            <h4 className="text-[18.17px] leading-[21.69px] tracking-[3%] text-black font-SFProDisplayFont font-medium]">
              {title}
            </h4>
            <p className="text-[16.36px] leading-[18.17px] font-normal tracking-[3%] text-black opacity-50 mt-2">
              {description}
            </p>
          </div>
          <Link to="/quote-form">
            <div>
              <Button className="w-full mt-6 border-[3px] border-[#efefef] hover:font-bold hover:text-[#2a2a2a] hover:bg-transparent hover:border-solid hover:border-[3px] hover:border-[#2a2a2a]">
                Order Now
              </Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MaterialItem;
