import React from "react"
import Skills from "../../assets/images/Skills-Verification/skills-order.svg"

const SkillsVerification = () => {
  return (
    <>
      <div className="h-[500px] bg-[color:var(--mycolor)] flex flex-col justify-center items-center">
        <div className="max-w-[80%]">
          <div className="text-center max-w-[92%]  md:max-w-[80%] mx-auto mb-12">
            <h3 className="text-[color:var(--para-heading-color)] text-[34px] md:text-[36px] font-SFProDisplayFont md:text-center font-bold leading-[1.0] break-words">
              How we verify our skilled workers?
            </h3>
            <p
              id="skills-para"
              className="w-full text-[color:var(--para-heading-color)] opacity-[0.80] text-[21.11px] md:text-[23.11px] font-SFProDisplayFont font-normal leading-[1.1] break-words mt-3"
            >
              We verify and register the service providers after intense security because customer care and security are
              of utmost importance to us.
            </p>
          </div>
          <div id="skills-image" className="w-full">
            <img src={Skills} alt="Skills Verification Process" className="w-full object-cover" />
          </div>
        </div>
      </div>
    </>
  )
}

export default SkillsVerification
