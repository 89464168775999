import React from "react"
import RenovationIntro from "../../assets/images/Rennovation-Images/Rectangle41.svg"
import TickFrame from "../../assets/images/Rennovation-Images/Frame.svg"

const RenovationVideo = () => {
  const content = [
    {
      imageUrl: TickFrame,
      description: "From concept to completion, our skilled team will breathe new life into you.",
    },
    {
      imageUrl: TickFrame,
      description: "From concept to completion, our skilled team will breathe new life into you.",
    },
    {
      imageUrl: TickFrame,
      description: "From concept to completion, our skilled team will breathe new life into you.",
    },
    {
      imageUrl: TickFrame,
      description: "From concept to completion, our skilled team will breathe new life into you.",
    },
  ]

  return (
    <>
      <div className="lg:p-[110px] md:p-[80px] flex flex-col md:flex-row">
        <div className="w-[100%] md:w-[40%] p-8 md:p-0">
          <img src={RenovationIntro} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="md:pl-[40px] w-[100%] md:w-[60%] p-8 md:p-0 ">
          <h2 className="text-black text-[32px] lg:text-[36px]  font-SFProDisplayFont font-bold leading-[1.2] break-words after:content-[''] after:w-[15%] after:mt-[30px] after:h-[5px] after:bg-[#FFB400] after:block">
            We Provide Professional <span className="lg:block">Renovation For You </span>
          </h2>
          {/* Map Start Here*/}
          {content.map((item, index) => (
            <div key={index} className="mt-[40px] flex justify-between">
              <div className="flex justify-center items-center w-[7%]">
                <img src={item.imageUrl} alt="" />
              </div>
              <div className="w-[92%] pl-2">
                <p className="text-[#282828] opacity-[0.50] text-[22px] font-SFProDisplayFont font-normal leading-[1.2] break-words md:leading-[1.1] md:text-[20px] lg:text-[22px] lg:leading-[1.2]">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
          {/* Map End Here */}
        </div>
      </div>
    </>
  )
}

export default RenovationVideo
