import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import ProjectsPage from "./Pages/ProjectsPage";
// import ProjectDetailPage from "./Pages/ProjectDetailPage"
import RenovationPage from "./Pages/RenovationPage";
import MaintenancePage from "./Pages/MaintenancePage";
import ConstructionPage from "./Pages/Construction";
import ElevatorPage from "./Pages/ElevatorPage";
import ConstructionMaterial from "./Pages/ConstructionMaterial";
import SolarPanelPage from "./Pages/SolarPanelPage";
import QuoteForm from "./Pages/QuoteForm";
import BlogsPage from "./Pages/Blogs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/blogs/:category",
        element: <BlogsPage />,
      },
      {
        path: "/services/construction",
        element: <ConstructionPage />,
      },
      {
        path: "/services/construction-material",
        element: <ConstructionMaterial />,
      },
      {
        path: "/services/solar-details",
        element: <SolarPanelPage />,
      },
      // {
      //   path: "/projects",
      //   element: <ProjectsPage />,
      // },
      {
        path: "/projects/:category", // Use a dynamic parameter for the category
        element: <ProjectsPage />,
      },
      {
        path: "/services/elevator",
        element: <ElevatorPage />,
      },
      {
        path: "/services/maintenance",
        element: <MaintenancePage />,
      },
      {
        path: "/services/renovation",
        element: <RenovationPage />,
      },
    ],
  },
  {
    path: "/quote-form",
    element: <QuoteForm />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router}></RouterProvider>);
