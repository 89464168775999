import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import Quote from "../components/Quote"

function QuoteForm() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <Quote />
    </div>
  )
}

export default QuoteForm
