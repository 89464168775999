import React from "react";
import Reviews from "./Reviews";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UserReviews = ({ heading = "", bgColor = true, reviews = [] }) => {
  const settings = {
    // dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {bgColor && (
        <div className="h-[700px] w-full">
          <div
            id="text-section"
            className="bg-[color:var(--mycolor)] h-[350px] sm:p-[44px] px-[10px] py-11"
          >
            <h1 className="text-[color:#2A2A2A] sm:text-[36px] text-[30px] text-center font-SFProDisplayFont font-bold leading-[1.2] break-words after:content-[''] sm:after:w-[8.5%] 2xl:after:w-[4.5%] after:w-[15%] after:m-auto after:mt-[22px] sm:after- after:h-[5px] after:bg-[color:#2a2a2a] after:block">
              {(heading = "  What our Clients are saying? ")}
            </h1>
          </div>
          <div
            id="image-section"
            className="bg-[color:#EFEFEF] h-[350px] relative flex justify-between flex-wrap m-auto"
          >
            <div className="w-[90%] m-[auto!important] mt-0 absolute top-[-59%] left-[5%]">
              <Slider {...settings} arrows={false}>
                {reviews.map((review, index) => (
                  <Reviews key={index} review={review} className="" />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
      {!bgColor && (
        <div className="sm:h-[700px] h-auto w-full">
          <div
            id="text-section"
            className="bg-[color:#2A2A2A] h-[350px] sm:p-[44px] px-[10px] py-11"
          >
            <h1 className="text-[color:#FFB400] sm:text-[36px] sm:w-[75%] md:w-[60%] w-full m-auto text-[30px] text-center font-SFProDisplayFont font-bold leading-[1.2] break-words after:content-[''] sm:after:w-[8.5%] 2xl:after:w-[4.5%] after:w-[15%] after:m-auto after:mt-[22px] after:h-[5px] after:bg-[color:#FFB400] after:block">
              {heading}
            </h1>
          </div>
          <div
            id="image-section"
            className="bg-[color:#EFEFEF]  h-[350px] relative flex justify-between flex-wrap m-auto"
          >
            <div className="w-[90%] m-auto absolute top-[-59%] left-[5%] ">
              <Slider {...settings} arrows={false}>
                {reviews.map((review, index) => (
                  <Reviews review={review} className="" key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserReviews;
