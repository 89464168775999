import React from "react";
import Tool from "../../assets/images/HeroSection-Images/tools.svg";
import Frame from "../../assets/images/HeroSection-Images/Frame-Right.svg";
import ServiceItem from "./ServiceItem";
import Renovation from "../../assets/images/HeroSection-Images/Renovation.svg";
import Construction from "../../assets/images/HeroSection-Images/Construction-Service.svg";
import Material from "../../assets/images/HeroSection-Images/Material.svg";
import Elevator from "../../assets/images/HeroSection-Images/Elevator.svg";
import SolarPanel from "../../assets/images/HeroSection-Images/solar-energy.png";

const Services = () => {
  const listItems = [
    {
      id: 0,
      imageUrl: Construction,
      title: "Construction Services",
      path: "/services/construction",
      description:
        "Transforming visions into reality. We deliver precision, quality and innovation in every project.",
      icon: "Construction",
    },
    {
      id: 1,
      imageUrl: Elevator,
      title: "Elevator / Escalator Services",
      path: "/services/elevator",
      description:
        "Top-Notch Installation and Maintenance Services for Both Imported and Semi-Imported Models.",
      icon: "Elevator",
    },
    {
      id: 2,
      imageUrl: Renovation,
      title: "Renovation Services",
      path: "/services/renovation",
      description:
        "Let's Refresh Your Spaces Through Our Expert Renovation Services.",
      icon: "Renovation",
    },
    {
      id: 3,
      imageUrl: Tool,
      title: "Maintenance Services",
      path: "/services/maintenance",
      description:
        "Keeping Your Spaces in Top Shape with Reliable Maintenance Services.",
      icon: "Hammer-Wrench",
    },
    {
      id: 4,
      imageUrl: Material,
      title: "Construction Material Supply",
      path: "/services/construction-material",
      description:
        "Supplying the Foundation of Your Projects with Quality Construction Materials.",
      icon: "Construction",
    },
    {
      id: 5,
      imageUrl: SolarPanel,
      title: "Solar Panel Installation",
      path: "/services/solar-details",
      description:
        "Efficient solar panel installation for sustainable and clean energy solutions.",
      icon: "Solar Panel",
    },
  ];

  const newServicesList = listItems.map(
    ({ id, imageUrl, title, description, icon, path }) => (
      <ServiceItem
        key={id}
        imageUrl={imageUrl}
        title={title}
        description={description}
        icon={icon}
        Frame={Frame}
        path={path}
      />
    )
  );

  return (
    <>
      <div className="services bg-[var(--mycolor)] w-full pt-[5px] pr-[5px] sm:pb-[65px] md:pb-[45px] lg:pb-[28px] pb-[35px] pl-[5px] 2xl:pr-[49px] 2xl:pl-[35px] xl:pr-[49px] xl:pl-[35px] lg:px-[20px] md:px-[15px] sm:pl-[35px">
        <div className="heading">
          <h2 className="font-SFProDisplayFont text-[32px] text-[#2A2A2A]  font-bold  md:text-center sm:text-center lg:text-left">
            Our Services
          </h2>
        </div>
        <div className="services-list flex flex-col justify-between 2xl:h-[510px] h-[410px] w-full lg:w-[97%] md:w-[55%] sm:w-[87%] sm:m-auto ">
          {newServicesList}
        </div>
      </div>
    </>
  );
};

export default Services;
