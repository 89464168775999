import React from "react";
import Clients from "./Clients";
import "../../App.css";
// import Clients from "../../assets/images/Clients-Images/Clients.svg";

const Testimonials = () => {
  const testimonialList = [
    {
      description:
        "“Kardaan truly went above and beyond in building my dream home. From the initial planning stages to the final touches, their expertise shone through. Their clear communication made the process stress-free, and the craftsmanship is second to none. Thank you, Kardaan, for making my vision a reality!”",
      name: "Mr Khurram ( Abbottabad )",
    },
    {
      description:
        "“We are thrilled with the elevator service provided by Kardaan at our hospital. The installation was seamless, and the elevators have been operating smoothly ever since. Our patients, visitors, and staff appreciate the reliability and efficiency of the service. Thank you for your excellent work! ”",
      name: "Dr Wajid ( MD Prime Medical College)",
    },

    {
      description:
        "“Working with Kardaan was a pleasure from start to finish. Their professionalism and attention to detail set them apart. I always felt well-informed and involved in the process, thanks to their clear communication. The quality of work is outstanding, and I'm thrilled with the final result. Highly recommended! ”",
      name: "Obaid Ullah ( Abbottabad)",
    },
  ];

  return (
    <>
      <div className="testimonial-section bg-[color:var(--section-background)] flex justify-evenly flex-col pt-[35px] pb-[30px] md:px-[50px] xl:px-[90px]">
        <h3 className="font-SFProDisplayFont text-center sm:mt-[20px] mt-0 sm:text-[40px] text-[30px] text-[color:#2A2A2A] font-bold after:content-[''] sm:after:w-[15%] lg:after:w-[5%] after:w-[12%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block after:my-[25px]">
          What our Clients are saying?
        </h3>
        <div className="testimonials-list pt-4 flex flex-col gap-4 md:flex-row md:flex-wrap justify-center  sm:mx-[35px] md:mx-0 mx-[15px]">
          {testimonialList.map((testimonial, index) => (
            <div
              key={index}
              className="rating flex flex-col gap-4 items-center  bg-white w-full sm:w-[100%] md:w-[48%] lg:w-[41%] xl:w-[32%] 2xl:w-[32.3333%] pt-[30px] sm:px-[25px] px-[10px] pb-[40px]"
              style={{ maxHeight: "420px" }}
            >
              <div className="review-upper-section max-h-[390px] sm:h-[210px] md:h-[290px] lg:h-[280px] xl:h-[300px]">
                <div className="icons flex justify-evenly items-center w-4/5 m-auto">
                  <i className="fas fa-star checked text-[orange] text-[24px]"></i>
                  <i className="fas fa-star checked text-[orange] text-[24px]"></i>
                  <i className="fas fa-star checked text-[orange] text-[24px]"></i>
                  <i className="fas fa-star checked text-[orange] text-[24px]"></i>
                  <i className="fas fa-star checked text-[orange] text-[24px]"></i>
                </div>
                <div className="testimonial-description mt-5 h-[276px] overflow-y-scroll">
                  <p className="font-SFProDisplayFont text-base text-[#666666] 2xl:text-[18px] font-normal leading-[27px] tracking-[1.0px] [word-spacing:5px] break-words text-left ">
                    {testimonial.description}
                  </p>
                </div>
              </div>
              <div className="max-h-[30px] h-[30px] flex w-full">
                <h4 className="font-SFProDisplayFont text-[#2A2A2A] text-[16px] 2xl:text-[18px] font-medium self-start leading-[50.40px] tracking-[0.32px] break-words">
                  {testimonial.name}
                </h4>
              </div>
            </div>
          ))}
        </div>
        <div className="clients mt-6">
          <Clients />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
