import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MaintenanceHeroSection from "../components/MaintenanceComponents/MaintenanceHeroSection";
import SkillsVerification from "../components/MaintenanceComponents/SkillsVerification";
import About from "../components/About";
// import ProjectItem from "../components/ProjectItem";
// import AboutUsImage from "../assets/images/AboutUs-Images/MaintenanceAbout.png";
import MaintenanceServices from "../components/MaintenanceComponents/MaintenanceService";
import KardaanWorking from "../components/MaintenanceComponents/KardaanWorking";
import UserReviews from "../components/MaintenanceComponents/UserReviews";
import Maintenance from "../assets/videos/Maintenance.mp4";
function MaintenancePage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const projects = [];
  const users = [
    {
      description:
        "“Kardaan truly went above and beyond in building my dream home. From the initial planning stages to the final touches, their expertise shone through. Their clear communication made the process stress-free, and the craftsmanship is second to none. Thank you, Kardaan, for making my vision a reality!”",
      name: "Mr Khurram ( Abbottabad )",
    },
    {
      description:
        "“Outstanding service provided by Kardaan team during the construction of my home. From start to finish, Kardaan professionalism, expertise, and attention to detail were truly exceptional. Kardaan team clear communication and transparency throughout the project ensured that I was always well-informed and confident in the process. The quality of workmanship demonstrated by your team surpassed my expectations, resulting in a home that perfectly reflects my vision. Thanks”",
      name: "Malik Shoukat Ali ( Abbottabad)",
    },

    {
      description:
        "“Working with Kardaan was a pleasure from start to finish. Their professionalism and attention to detail set them apart. I always felt well-informed and involved in the process, thanks to their clear communication. The quality of work is outstanding, and I'm thrilled with the final result. Highly recommended! ”",
      name: "Obaid Ullah ( Abbottabad)",
    },
  ];
  return (
    <>
      <MaintenanceHeroSection />
      {/* About Component Start */}
      <About
        showDownloadButton
        className="md:flex-row"
        showHeaderVertical={false}
        imgUrl=""
        // thumbnailUrl={AboutUsImage}
        videoUrl={Maintenance}
        heading="About us"
        capitalizeCase={true}
      >
        <div>
          <p className="text-[15px] leading-[26px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 text-justify">
            Order through Kardaan App, reach out to your nearby handyman and get
            your work done without any hassle!
          </p>
          <p className="text-[15px] leading-[26px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 text-justify">
            Kardaan is a Farsi word used to describe a handyman or a skilled
            person. In the world of digitization, our blue collar labor has been
            left behind, Kardaan aims to empower them so that they can earn for
            themselves in a more respectable manner and provide for their
            families.
          </p>
          <p className="text-[15px] leading-[26px] tracking-[4%] font-SFProDisplayFont text-[#666666] p-0 m-0 text-justify">
            HELP US CHANGE LIVES …{" "}
          </p>
        </div>
      </About>
      {/* About Component Ends*/}
      {/* Skills Verification Component */}
      <SkillsVerification />
      {/* Maintenance Services Component */}
      <MaintenanceServices />
      {/* <div className="bg-[#E1E1E1] py-20">
        <div className="projects-main w-[85%] mx-auto">
          <h3 className="text-center m-auto sm:text-[40px] text-[30px] font-bold after:content-[''] sm:after:w-[6%] after:w-[12%] after:m-auto after:h-[5px] after:bg-[color:var(--mycolor)] after:block mb-8">
            Latest Projects
          </h3>
          <ProjectItem projects={projects} showButton />
        </div>
      </div> */}
      {/* Kardaan Working Component */}
      <KardaanWorking />
      {/* User Reviews Component */}
      <UserReviews reviews={users} />
      {/* <Reviews /> */}
    </>
  );
}

export default MaintenancePage;
