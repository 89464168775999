/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import MaintenanceProjects from "./MaintenanceProjects"
import ProjectItem from "../ProjectItem";
import ProjectOneImage from "../../assets/images/Elevator-Images/AQ4.jpg";
import ProjectThreeImage from "../../assets/images/Elevator-Images/PM1.jpg";
import ProjectTwoImage from "../../assets/images/Elevator-Images/IBC2.jpg";
import ProjectFourImage from "../../assets/images/Construction/Supply-Road-Project/Thumbnail.jpg";
import ProjectFiveImage from "../../assets/images/Construction/Batakundi-Naran-Hotel-Project/thumnail.jpg";

const Item = ({ itemText, isActive, handleClick }) => {
  return (
    <div
      className={`tab-item ${
        isActive ? "active" : ""
      } cursor-pointer border-2 border-solid ${
        isActive ? "border-[var(--button-border-color)]" : "border-[#666666]"
      } px-[45px] py-[12px] text-16.82 font-bold tracking-1.68 ${
        isActive ? "text-white" : "text-[#666666]"
      }
        ${isActive ? "bg-[#2a2a2a]" : "bg-none"}
        text-center w-[213px] my-1`}
      onClick={handleClick}
    >
      <p className="text-[15px]">{itemText}</p>
    </div>
  );
};

const LatestProjects = () => {
  const [activeIndex, setActiveIndex] = useState("ALL_PROJECTS");
  const [projectItems, setProjectItems] = useState([]);
  const projects = [
    {
      id: 1,
      projectCategory: "ALL_PROJECTS",
      itemText: "ALL ",
      category: "Abu Qasim",
      image: ProjectOneImage,
      title: "Abul Qasim Super Market Bahria Town Karachi",
      description:
        "Discover elevated shopping at AQ Super Market, Bahria Town Karachi, with modern elevators by Kardaan Labour, ensuring convenience as you explore our diverse range effortlessly.",
    },
    {
      id: 2,
      projectCategory: "ALL_PROJECTS",
      itemText: "ALL ",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 3,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "CONSTRUCTION",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 4,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      category: "Abu Qasim",
      image: ProjectOneImage,
      title: "Abul Qasim Super Market Bahria Town Karachi",
      description:
        "Discover elevated shopping at AQ Super Market, Bahria Town Karachi, with modern elevators by Kardaan Labour, ensuring convenience as you explore our diverse range effortlessly.",
    },
    {
      id: 5,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      category: "Prime Medical",
      image: ProjectThreeImage,
      title: "Prime Medical College, Near 26 Num, Islamabad",
      description:
        "Elevators, expertly installed by Kardaan Labour's team, now grace Prime Medical College in Islamabad, enhancing accessibility and ensuring a smooth, efficient environment for all.",
    },
    {
      id: 6,
      projectCategory: "ELEVATOR_TAB",
      itemText: "ELEVATOR",
      category: "IBC City",
      image: ProjectTwoImage,
      title: "Infinity Business Center, Mumtaz City, Islamabad",
      description:
        "Experience elevated living in IBC Mumtaz City, Islamabad, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
    },
    {
      id: 7,
      projectCategory: "ALL_PROJECTS",
      itemText: "ALL ",
      category: "IBC City",
      image: ProjectTwoImage,
      title: "Infinity Business Center, Mumtaz City, Islamabad",
      description:
        "Experience elevated living in IBC Mumtaz City, Islamabad, where Kardaan's elevators ensure seamless vertical mobility for delighted residents, enhancing modern convenience.",
    },
    {
      id: 8,
      projectCategory: "MAINTENANCE_TAB",
      itemText: "MAINTENANCE",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 9,
      projectCategory: "CONSTRUCTION_TAB",
      itemText: "CONSTRUCTION",
      category: "Batakundi Naran Hotel Project",
      image: ProjectFiveImage,
      title: "Batakundi Naran Hotel Project",
      description:
        "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
    },
    {
      id: 10,
      projectCategory: "RENOVATIONS_TAB",
      itemText: "RENOVATIONS",
      category: "Iqbal Road Site",
      image: ProjectFourImage,
      title: "Construction Site Abbottabad ( Iqbal Road Supply )",
      description:
        "Iqbal Road construction site in Abbottabad, meticulously crafted by skilled Kardaan workers, showcasing their expertise in delivering excellence, precision, and dedication.",
    },
    {
      id: 11,
      projectCategory: "MAINTENANCE_TAB",
      itemText: "MAINTENANCE",
      category: "Batakundi Naran Hotel Project",
      image: ProjectFiveImage,
      title: "Batakundi Naran Hotel Project",
      description:
        "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
    },
    {
      id: 12,
      projectCategory: "RENOVATIONS_TAB",
      itemText: "RENOVATIONS",
      category: "Batakundi Naran Hotel Project",
      image: ProjectFiveImage,
      title: "Batakundi Naran Hotel Project",
      description:
        "Batakundi Naran Hotel Project: Exemplifying craftsmanship and dedication, our skilled Kardaan workers bring excellence and precision to life, akin to the Iqbal Road construction site in Abbottabad.",
    },
  ];

  const menuItems = [
    { tab: "ALL_PROJECTS", itemText: "ALL PROJECTS" },
    { tab: "CONSTRUCTION_TAB", itemText: "CONSTRUCTION" },
    { tab: "MAINTENANCE_TAB", itemText: "MAINTENANCE" },
    { tab: "RENOVATIONS_TAB", itemText: "RENOVATIONS" },
    { tab: "ELEVATOR_TAB", itemText: "ELEVATOR" },
  ];

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  // React.useEffect(() => {
  //   if (!activeIndex || activeIndex === "CONSTRUCTION_TAB") {
  //     setProjectItems(() => [...projects])
  //   } else {
  //     const filterData = projects.filter((item) => item.projectCategory === activeIndex)
  //     setProjectItems([...filterData])
  //   }
  // }, [activeIndex, setProjectItems])
  // React.useEffect(() => {
  //   const filterData = projects.filter(
  //     (item) => item.projectCategory === activeIndex
  //   );
  //   setProjectItems([...filterData]);
  // }, [activeIndex, setProjectItems]);
  React.useEffect(() => {
    const filterData = projects.filter(
      (item) => item.projectCategory === activeIndex
    );

    // Check if there are projects for the selected category
    if (filterData.length > 0) {
      setProjectItems([...filterData]);
    } else {
      // If no projects are available for the selected category, set an empty array
      setProjectItems([]);
    }
  }, [activeIndex, setProjectItems]);

  const newMenuItems = menuItems.map(({ tab, itemText }) => (
    <Item
      key={tab}
      itemText={itemText}
      isActive={tab === activeIndex}
      handleClick={() => handleItemClick(tab)}
    />
  ));

  return (
    <>
      <div className="projects-main w-[87%] mx-auto" id="home-projects">
        <h3 className="text-center m-auto sm:text-[40px] text-[30px] font-bold after:content-[''] sm:after:w-[15%] lg:after:w-[5%] after:w-[15%] after:m-auto after:mt-5 after:h-[5px] after:bg-[color:var(--mycolor)] after:block pb-15px">
          Latest Projects
        </h3>
        <div className="projects-tabs flex flex-wrap justify-center items-center xl:justify-between gap-1 my-8">
          {newMenuItems}
        </div>
        {/* <MaintenanceProjects projects={projectItems} /> */}
        <ProjectItem projects={projectItems} showButton />
      </div>
    </>
  );
};

export default LatestProjects;
