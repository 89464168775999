import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import BlogOneImage from "../assets/images/Blogs-Images/Kardaan1.jpg";
import BlogTwoImage from "../assets/images/Blogs-Images/Kardaan2.webp";
import BlogThreeImage from "../assets/images/Blogs-Images/Kardaan3.webp";
import BlogFourImage from "../assets/images/Blogs-Images/Kardaan4.png";
import { Link } from "react-router-dom";

const Blogs = () => {
  const { category } = useParams();
  const location = useLocation();

  const recentPosts = [
    {
      title: "Services at your door step",
      slug: "/blog/services-at-your-door-step",
      category: "door-step",
    },
    {
      title: "Which Service Provider are you Looking For??",
      slug: "/blog/which-service-provider-are-you-looking-for",
      category: "service-provider",
    },
    {
      title: "Kardaan Hai Naa!",
      slug: "/blog/kardaan-hai-naa!",
      category: "kardaan",
    },
    {
      title: "Kardaan is a digital platform",
      slug: "/kardaan-is-a-digital-platform",
      category: "digital-platform",
    },
  ];

  const blogDetails = [
    {
      title: "Services at your door step",
      image: BlogOneImage,
      description: {
        para1:
          "Plumber, Electrician, Carpenter, Painter, AC Technician, Gardener Ya Welding ka koe b kaam ho, tu ghar bethay Kardaan App pe order place karen aur befikar ho jaen.",
        para2: "Q K Kardaan daita hai Ba-ehtimad aur quality services.",
      },
      hashtags:
        "#maintenance #construction #doorstep #islamabadians #abbottabad #rawalpindians #startupstory",
      category: "door-step",
    },
    {
      title: "Which Service Provider are you Looking For??",
      image: BlogTwoImage,
      description: {
        para1:
          "Kardaan is a mobile app through which you can directly connect with nearby skilled service providers (Electrician, Plumbers, Carpenter, Painter, Helper, AC Technician, Mason etc) in your area and get your work done Instantly.",
        para2:
          "Launching Soon in the twin cities, Islamabad and Rawalpindi. Stay Tuned!",
      },
      hashtags:
        "#services #homes #organizations #corporates #Islamabad #Rawalpindi #digitalplatform #smartlife #smartsolution #technology #launchingsoon #kardaan",
      category: "service-provider",
    },
    {
      title: "Kardaan Hai Naa!",
      image: BlogThreeImage,
      description: {
        para1:
          "Kardaan is a digital platform through which you can directly connect with nearby skilled service providers in your area and get your work done.",
        para2:
          "Launching Soon to twin cities Islamabad and Rawalpindi. Stay Tuned !",
      },
      hashtags:
        "#services #homes #organizations #corporates #Islamabad #Rawalpindi #digitalplatform #smartlife #smartsolution #technology #launchingsoon #kardaan",
      category: "kardaan",
    },
    {
      title: "Kardaan is a digital platform",
      image: BlogFourImage,
      description: {
        para1:
          "Kardaan is a digital platform through which you can directly connect with nearby skilled service providers in your area and get your work done.",
        para2:
          "Launching Soon to twin cities Islamabad and Rawalpindi. Stay Tuned!",
      },
      hashtags:
        "#services #homes #organizations #corporates #Islamabad #Rawalpindi #digitalplatform #smartlife #smartsolution #technology #launchingsoon #kardaan",
      category: "digital-platform",
    },
  ];

  const filteredBlogDetails = category
    ? blogDetails.filter(
        (blog) => blog.category.toLowerCase() === category.toLowerCase()
      )
    : blogDetails;
  const filteredRecentPosts = recentPosts.filter(
    (post) => post.category.toLowerCase() !== category.toLowerCase()
  );

  useEffect(() => {
    // console.log("Category:", category);
    // console.log("Filtered Blog Details:", filteredBlogDetails);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div className="bg-[#EFEFEF] xl:px-32 lg:px-24 sm:px-20 px-3 sm:py-20 py-4">
        {filteredBlogDetails.map((blog, index) => (
          <div key={index}>
            <div className="w-full">
              <h3 className="sm:text-[1.75rem] text-[1.5rem] sm:text-start text-center font-semibold after:content-[''] after:h-[3px!important]  lg:after:w-[14%] sm:after:w-[10%] sm:after:m-0 after:m-auto after:w-[12%] sm:after:mt-[18px] after:block after:bg-[color:var(--mycolor)]">
                {blog.title}
              </h3>
            </div>
            <div className="w-full flex flex-col lg:flex-row sm:flex-col justify-between">
              <div className="lg:w-[65%] sm:w-full h-auto mt-10 mb-4">
                <img
                  src={blog.image}
                  className="h-full w-full align-middle"
                  alt=""
                />
              </div>
              <div className="lg:w-[33%] md:w-[62%] sm:w-4/5 lg:mt-8">
                <div className="">
                  <h5 className="font-bold text-xl mb-3 after:content-[''] after:h-[3px!important] after:my-2 lg:after:w-[22%] md:after:w-[18%] sm:after:w-[8%] after:w-[10%] after:block after:bg-[color:var(--mycolor)]">
                    RECENT POSTS
                  </h5>
                  <ul className="list-none">
                    {filteredRecentPosts.map((post, index) => (
                      <li
                        key={index}
                        className="border-[1px] p-3 bg-white hover:bg-[#FFB400]"
                      >
                        <Link to={`/blogs/${post.category}`} className="">
                          {post.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-5 lg:w-[65%] w-full">
              <p className="mb-4">{blog.description.para1}</p>
              <p className="mb-4">{blog.description.para2}</p>
              <div className=" ">
                {category === "door-step" && (
                  <>
                    <p className="font-bold mb-4">
                      Place your order through Kardaan App:
                    </p>
                    <p className="mb-4 font-semibold">
                      For Android Users:{" "}
                      <a
                        href="http://shorturl.at/iszW1?fbclid=IwAR2NSG4wS18sqxDde46T6_kFbGD8-bqo40cmp6hY8prCwjVIVgO1GftKn-4"
                        className="text-blue-500 underline"
                      >
                        shorturl.at/iszW1
                      </a>
                    </p>
                    <p className="mb-4 font-semibold">
                      For iPhone Users:{" "}
                      <a
                        href="https://apple.co/2jW6ns8?fbclid=IwAR36qKZjX4gNQOTZqWN8wu0I_eYc2z6Hs7pMbq8WEcRss8Z_vpKppTCPJf8"
                        className="text-blue-500 underline"
                      >
                        https://apple.co/2jW6ns8
                      </a>
                    </p>
                    <p className="mb-4">
                      Kardaan services are currently available in Islamabad,
                      Rawalpindi, and Abbottabad only!
                    </p>
                  </>
                )}
              </div>
              <p>
                <a href="/" className="text-blue-500 hover:underline">
                  {blog.hashtags}{" "}
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Blogs;
